import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { FaArrowDown, FaArrowRight } from 'react-icons/fa6'
import { Link } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { parceiros } from '@/api/postParceiros'
import { ComoAjudarCard } from '@/components/comoajudar/comoajudar-card'
import { ContributionCard } from '@/components/contribution-card'
import { DoeCabeloList } from '@/components/doe-cabelo-list'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useMain } from '@/contexts/mainContext'

import depoimento1 from '../../assets/depoimentos/depoimento_1.jpg'
import depoimento2 from '../../assets/depoimentos/depoimento_2.jpg'
import depoimento3 from '../../assets/depoimentos/depoimento_3.jpg'
import icon from '../../assets/icon4.png'

const parceirosFormSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório!'),
  company: z.string().min(1, 'Empresa é obrigatório!'),
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
})

type ParceirosForm = z.infer<typeof parceirosFormSchema>

export function ComoAjudar() {
  const { setSuccess } = useMain()

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<ParceirosForm>({
    resolver: zodResolver(parceirosFormSchema),
  })

  const { mutateAsync: parceirosFn } = useMutation({
    mutationFn: parceiros,
    onSuccess: () => {
      toast.success('😄 Obrigado pela sua solicitação.')
      reset()
    },
    onError: () => {
      toast.error('🙁 Erro, tente novamente mais tarde!')
      reset()
    },
  })

  async function handleParceiros(data: ParceirosForm) {
    const parceirosData = {
      name: data.name,
      company: data.company,
      email: data.email,
    }

    await parceirosFn({ data: parceirosData })
  }

  return (
    <>
      <Helmet title="Como Ajudar" />
      <section className="flex items-center justify-center py-40 max-[1024px]:py-20">
        <div className="container">
          <h1 className="text-center font-roboto text-5xl font-bold text-purple-950 max-[1440px]:pb-8 max-[1024px]:pt-8 max-[480px]:text-4xl min-[1600px]:pb-16">
            Existem 3 maneiras de nos ajudar:
          </h1>
          <div className="flex justify-between gap-8 pt-16 max-[1440px]:pt-8 max-[1024px]:flex-col max-[1024px]:items-center max-[1024px]:justify-center max-[1024px]:pt-0">
            <Fade direction="left">
              <ComoAjudarCard
                borderColor="border-pink-650"
                bgColor="bg-pink-650"
                number={1}
                title={`Ajuda financeira`}
                subTitleColor="text-pink-650"
                subTitleBold="A cada R$ 100"
                subTitle={`arrecadados, uma peruca é produzida e doada a um paciente`}
                hoverColor="hover:bg-pink-650"
                link="adote-uma-peruca"
              />
            </Fade>
            <Fade delay={200}>
              <ComoAjudarCard
                borderColor="border-purple-950"
                bgColor="bg-purple-950"
                number={2}
                title={`Doação de cabelo`}
                subTitleColor="text-purple-950"
                subTitle={`Confira os requisitos para doar seu cabelo via Correios ou Pontos de Coleta`}
                hoverColor="hover:bg-purple-950"
                link="doe-seu-cabelo"
              />
            </Fade>
            <Fade direction="right">
              <ComoAjudarCard
                borderColor="border-cyan-650"
                bgColor="bg-cyan-650"
                number={3}
                title={`Apoio e patrocínio`}
                subTitleColor="text-cyan-650"
                subTitle={`Confira como a sua empresa pode apoiar a nossa causa`}
                hoverColor="hover:bg-cyan-650"
                link="patrocinador"
              />
            </Fade>
          </div>
        </div>
      </section>
      <section
        className="flex items-start justify-center bg-pink-650 max-[1024px]:pb-20"
        id="adote-uma-peruca"
      >
        <div className="container">
          <div className="flex justify-between max-[1024px]:flex-col-reverse">
            <div className="flex flex-col justify-center gap-6 max-[1024px]:items-center max-[1024px]:pt-10 lg:pt-20">
              <Fade delay={200}>
                <h2 className="pt-8 font-roboto text-4xl font-bold text-white max-[1024px]:text-center max-[480px]:text-3xl">
                  Adote uma peruca
                </h2>
              </Fade>
              <Fade delay={250}>
                <p className="font-roboto text-2xl font-normal text-white max-[1024px]:text-center max-[480px]:text-xl">
                  <strong>
                    Temos um estoque de mais de 2 toneladas{' '}
                    <br className="hidden max-[480px]:hidden lg:hidden" /> de
                    cabelo parado.
                  </strong>{' '}
                  <br />
                  Com a sua ajuda podemos{' '}
                  <br className="hidden max-[480px]:hidden lg:hidden" />
                  confeccionar 50 mil perucas.
                </p>
              </Fade>
              <Fade delay={300}>
                <div className="flex h-[60px] w-[540px] items-center justify-center rounded-full bg-purple-950 max-[1024px]:w-full max-[1024px]:px-8 max-[480px]:w-60 lg:mt-10">
                  <h4 className="font-roboto text-2xl font-bold text-white max-[624px]:text-xl max-[560px]:text-center max-[560px]:text-sm">
                    Faça a diferença, escolha{' '}
                    <br className="hidden max-[580px]:block lg:hidden" /> uma
                    opção abaixo:
                  </h4>
                </div>
              </Fade>
            </div>
            <div className="flex h-64 w-64 items-center justify-center rounded-bl-[6rem] rounded-br-[6rem] bg-purple-950 max-[1024px]:h-32 max-[1024px]:w-full">
              <h3 className="text-center font-roboto text-4xl font-bold text-white max-[480px]:text-xl">
                Onde mais <br className="hidden max-[480px]:hidden lg:block" />
                precisamos <br className="hidden max-[480px]:block lg:block" />{' '}
                de ajuda!
              </h3>
            </div>
          </div>
          <div className="grid grid-cols-auto-fit-160 items-center gap-4 py-12 max-[1024px]:justify-items-center max-[767px]:gap-12 max-[560px]:grid-cols-1 max-[480px]:gap-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
            <Fade delay={350}>
              <ContributionCard
                active
                title="Doação Única"
                subtitle="A cada R$100 uma peruca é produzida e doada a um paciente"
                url="https://doe.cabelegria.org/s/single_step"
              />
            </Fade>
            <Fade delay={400} className="justify-self-center">
              <ContributionCard
                title="Doação Mensal"
                subtitle="Com menos de R$0,85/dia você já nos ajuda a espalhar sorrisos"
                url="https://doe.cabelegria.org/s/single_step"
              />
            </Fade>
            <Fade
              delay={450}
              className="justify-self-end max-[1024px]:justify-self-center"
            >
              <ContributionCard
                title={`Doe agora\ncom um PIX`}
                subtitle="Chave: pix@cabelegria.org"
              />
            </Fade>
          </div>
          <Fade delay={500}>
            <div className="flex items-center justify-between max-[1024px]:flex-col max-[1024px]:gap-16 lg:pb-10">
              <div className="flex items-center gap-8 max-[1024px]:flex-col max-[1024px]:justify-center">
                <img
                  src={depoimento1}
                  alt="Ketlen Vieira"
                  className="h-[200px] w-[200px] rounded-full"
                />
                <p className="font-roboto text-base font-normal italic text-white max-[480px]:text-center">
                  &quot;Sou muito grata a vocês, porque desde o começo do meu
                  tratamento, eu sempre falava pra Deus que eu queria ter
                  condições de ter uma peruca boa e com cabelos grandes.{' '}
                  <br className="hidden max-[480px]:hidden lg:block" /> Aí me
                  apareceu vocês! Não tiro a minha da cabeça por nada e nem
                  parece que um dia tive câncer.&quot; <br />
                  <strong className="not-italic">Ketlen Vieira</strong>
                </p>
              </div>
              {/* <Button
              variant="transparent"
              size="lg"
              className="max-[480px]:h-[80px flex h-[70px] w-[440px] items-center justify-center rounded-full border-purple-950 bg-purple-950 text-left font-roboto text-xl font-medium text-white max-[1024px]:w-full max-[560px]:text-base max-[480px]:text-center"
            >
              Saiba mais sobre o processo de{' '}
              <br className="hidden max-[480px]:block lg:block" /> confecção das
              perucas
            </Button> */}
            </div>
          </Fade>
        </div>
      </section>
      <section
        className="flex items-start justify-center max-[1024px]:pb-20"
        id="doe-seu-cabelo"
      >
        <div className="container">
          <div className="flex justify-between  max-[1024px]:flex-col-reverse max-[1024px]:items-center">
            <div className="flex flex-col justify-center gap-6 max-[1024px]:items-center max-[1024px]:pt-10 lg:pt-20">
              <Fade delay={200}>
                <h2 className="pt-8 font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-3xl">
                  Doe seu cabelo
                </h2>
              </Fade>
              <Fade delay={250}>
                <p className="font-roboto text-xl font-normal text-purple-950 max-[480px]:text-center max-[480px]:text-xl">
                  Confira os requisitos necessários para enviar sua doação e
                  realize o pré-cadastro
                </p>
              </Fade>
              <Fade delay={300}>
                <div className="flex h-[60px] w-[340px] items-center justify-center rounded-full bg-cyan-650 max-[1024px]:w-full max-[1024px]:px-8 max-[480px]:w-60 lg:mt-10">
                  <h4 className="font-roboto text-2xl font-bold text-white max-[1024px]:text-xl max-[480px]:text-base">
                    Tamanho mínimo: 15cm
                  </h4>
                </div>
              </Fade>
            </div>
            <div className="h-64 w-64 max-[560px]:h-32 max-[560px]:w-32">
              <img src={icon} alt="Doe seu cabelo" />
            </div>
          </div>
          <div className="grid grid-cols-auto-fit-160 items-center gap-4 py-12 max-[1024px]:justify-items-center max-[767px]:gap-12 max-[639px]:grid-cols-1 max-[480px]:gap-6 sm:grid-cols-1 lg:grid-cols-3 xl:grid-cols-3 min-[1600px]:grid-cols-4">
            <Fade delay={350}>
              <div className="flex flex-col justify-center gap-6 max-[1024px]:items-center max-[1024px]:pt-0 lg:pt-20">
                <h3 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-3xl">
                  Aceitamos{' '}
                  <br className="hidden max-[1024px]:hidden lg:block" />
                  qualquer tipo{' '}
                  <br className="hidden max-[1024px]:hidden lg:block" />
                  de cabelo
                </h3>
                <p className="font-roboto text-2xl font-normal text-purple-950 max-[480px]:text-center max-[480px]:text-xl">
                  Mesmo com química e coloração!
                </p>
              </div>
            </Fade>
            <Fade delay={400}>
              <div className="flex h-96 items-center justify-center rounded-lg border-[1px] border-purple-950 max-[1024px]:px-4 max-[560px]:h-[27rem]">
                <DoeCabeloList />
              </div>
            </Fade>
            <div className="flex justify-self-center max-[1599px]:hidden">
              <Fade delay={450}>
                <FaArrowRight color="#482682" size={98} />
              </Fade>
            </div>
            <Fade
              delay={500}
              className="justify-self-end max-[1024px]:justify-self-center"
            >
              <Link to="/como-ajudar/cadastro">
                <Button
                  variant="transparent"
                  size="lg"
                  className="max-[560px]: flex h-[90px] w-[340px] items-center justify-center rounded-full bg-pink-650 text-center font-roboto text-2xl font-bold text-white max-[1024px]:w-full max-[480px]:text-base lg:mt-10 xl:justify-self-center"
                >
                  Realize o pré-cadastro{' '}
                  <br className="hidden max-[480px]:block lg:block" /> para
                  enviar sua doação!
                </Button>
              </Link>
            </Fade>
          </div>
          <div className="flex items-center justify-between gap-8 max-[1024px]:flex-col max-[1024px]:gap-16 lg:py-10">
            <Fade delay={550}>
              <div className="flex items-center gap-8 max-[1024px]:flex-col max-[1024px]:items-center max-[1024px]:justify-center">
                <img
                  src={depoimento2}
                  alt="Aline Matulevic"
                  className="h-[200px] w-[200px] rounded-full"
                />
                <p className="max-w-[26rem] font-roboto text-base font-normal italic text-purple-950 max-[480px]:text-center">
                  &quot;Esse ano foi a quinta vez que cortei o cabelo para
                  doação. É extremamente gratificante saber que um pouquinho de
                  nós pode ajudar uma pessoa.&quot; <br />
                  <strong className="not-italic">Aline Matulevic</strong>
                </p>
              </div>
            </Fade>
            <Fade delay={600}>
              <div className="flex items-center gap-8 max-[1024px]:flex-col max-[1024px]:items-center max-[1024px]:justify-center">
                <img
                  src={depoimento3}
                  alt="Tais Yuki"
                  className="h-[200px] w-[200px] rounded-full"
                />
                <p className="max-w-[26rem] font-roboto text-base font-normal italic text-purple-950 max-[480px]:text-center">
                  &quot;Essa experiência de doação me lembrou da importância de
                  ajduar os outros, mesmo quando estamos enfrentando nossos
                  próprios desafios.&quot; <br />
                  <strong className="not-italic">Tais Yuki</strong>
                </p>
              </div>
            </Fade>
          </div>
          <Fade delay={650}>
            <div className="flex items-center justify-center max-[1024px]:flex-col max-[1024px]:gap-16 max-[1024px]:py-10 lg:py-10">
              <Link to="/certificado" onClick={() => setSuccess(false)}>
                <Button
                  variant="transparent"
                  size="lg"
                  className="flex h-[70px] w-[800px] items-center justify-center rounded-full border-[1px] border-purple-950 bg-transparent font-roboto text-3xl font-bold text-purple-950 hover:bg-purple-950 hover:text-white max-[1024px]:w-full max-[560px]:text-base max-[480px]:h-[80px] max-[480px]:text-center"
                >
                  Acompanhe aqui o{' '}
                  <br className="hidden max-[1024px]:hidden max-[560px]:block lg:hidden" />
                  seu certificado de doação
                </Button>
              </Link>
            </div>
          </Fade>
        </div>
      </section>
      <section
        className="flex items-center justify-center bg-cyan-650 py-40 max-[1024px]:pb-20"
        id="patrocinador"
      >
        <div className="container">
          <div className="flex flex-col items-center justify-center gap-8">
            <Fade delay={200}>
              <h1 className="pt-10 text-center font-roboto text-5xl font-bold text-white max-[480px]:pb-0 max-[480px]:text-4xl">
                Quero me tornar um parceiro ou patrocinador
              </h1>
            </Fade>
            <Fade delay={250}>
              <p className="text-center font-roboto text-2xl font-normal text-white max-[480px]:text-xl">
                Se você gostaria de saber mais sobre nossas Oficinas
                Corporativas, contratação do Banco de Perucas Móvel para
                eventos, palestras e outras possibilidades de parceria com a
                Cabelegria, entre em contato conosco!
              </p>
            </Fade>
            <Fade delay={300}>
              <Link
                to="/assets/pdfs/Midia_Kit_Cabelegria_2024.pdf"
                target="_blank"
              >
                <Button
                  variant="transparent"
                  size="lg"
                  className="flex h-[60px] w-[500px] items-center justify-center gap-4 rounded-full bg-white font-roboto text-2xl font-bold text-cyan-650 max-[1024px]:w-full max-[560px]:text-sm"
                >
                  Clique aqui e baixe nosso mídia kit{' '}
                  <FaArrowDown className="max-[480px]:hidden" />
                </Button>
              </Link>
            </Fade>

            <form
              className="flex w-1/2 flex-col gap-4 max-[1024px]:w-full max-[1024px]:justify-center max-[1024px]:py-0"
              onSubmit={handleSubmit(handleParceiros)}
            >
              {' '}
              <Fade delay={350} className="text-center">
                <div className="w-full">
                  <Input
                    id="nome"
                    type="text"
                    placeholder="Seu Nome"
                    {...register('name')}
                    className={
                      errors.name?.message
                        ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                        : 'focus:ring-red border-[1px] border-solid bg-transparent font-roboto font-medium capitalize text-white outline-none placeholder:font-roboto placeholder:text-white focus:ring-purple-950 focus-visible:outline-none focus-visible:ring-purple-950'
                    }
                  />
                  {errors.name?.message && (
                    <p className="pt-1 text-left text-sm text-red-600">
                      {errors.name.message}
                    </p>
                  )}
                </div>

                <div className="flex gap-4 max-[480px]:flex-col">
                  <div className="w-full">
                    <Input
                      id="empresa"
                      type="text"
                      placeholder="Empresa"
                      {...register('company')}
                      className={
                        errors.company?.message
                          ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                          : 'focus:ring-red border-[1px] border-solid bg-transparent font-roboto font-medium capitalize text-white outline-none placeholder:font-roboto placeholder:text-white focus:ring-purple-950 focus-visible:outline-none focus-visible:ring-purple-950'
                      }
                    />
                    {errors.company?.message && (
                      <p className="pt-1 text-left text-sm text-red-600">
                        {errors.company.message}
                      </p>
                    )}
                  </div>
                  <div className="w-full">
                    <Input
                      id="email"
                      type="email"
                      placeholder="E-mail"
                      {...register('email')}
                      className={
                        errors.email?.message
                          ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                          : 'focus:ring-red border-[1px] border-solid bg-transparent font-roboto font-medium text-white outline-none placeholder:font-roboto placeholder:text-white focus:ring-purple-950 focus-visible:outline-none focus-visible:ring-purple-950'
                      }
                    />
                    {errors.email?.message && (
                      <p className="pt-1 text-left text-sm text-red-600">
                        {errors.email.message}
                      </p>
                    )}
                  </div>
                </div>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant="transparent"
                  className="mx-auto w-32 bg-white font-roboto text-sm font-medium text-cyan-650"
                >
                  Enviar
                </Button>
              </Fade>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}

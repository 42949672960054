// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'

import { Fragment, useEffect } from 'react'
// import required modules
import { Navigation } from 'swiper/modules'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

import { useMain } from '@/contexts/mainContext'

import depoimentosArr from '../utils/depoimentosslide.json'

export function SlideDepoimentos() {
  /// assets/slides/depoimentos/${slide.imagem}

  const { depoimentosSlide, setDepoimentosSlide } = useMain()

  useEffect(() => {
    const response = depoimentosArr

    setDepoimentosSlide(response)
  }, [depoimentosSlide])

  return (
    <Swiper navigation={true} modules={[Navigation]}>
      {depoimentosSlide.map((slide) => {
        return (
          <Fragment key={slide.id}>
            {slide.show === true && (
              <SwiperSlide key={slide.id}>
                <div className="flex flex-row gap-8 rounded-xl bg-pink-650 px-16 py-8 max-[1024px]:flex-col max-[1024px]:px-8">
                  <img
                    src={`https://www.cabelegria.org/assets/slides/depoimentos/${slide.imagem}`}
                    alt={`Depoimento ${slide.id}`}
                    className="w-[440px] object-contain max-[1024px]:mx-auto max-[1024px]:object-cover"
                  />
                  <div className="flex flex-col justify-start gap-2">
                    <h1 className="w-60 border-b border-b-white text-center font-roboto text-2xl font-bold text-white max-[1024px]:w-auto">
                      {slide.title}
                    </h1>
                    <p
                      className="pt-4 font-roboto text-sm font-light text-white"
                      dangerouslySetInnerHTML={{ __html: slide.texto }}
                    />
                  </div>
                </div>
              </SwiperSlide>
            )}
          </Fragment>
        )
      })}
    </Swiper>
  )
}

import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { FaArrowRight } from 'react-icons/fa6'
import ReactPlayer from 'react-player'

import { Button } from '@/components/ui/button'
import { useMain } from '@/contexts/mainContext'

import icon3 from '../../assets/girl.jpg'
import icon4 from '../../assets/heart.jpg'
import icon2 from '../../assets/machine.jpg'
import icon1 from '../../assets/scissors.jpg'

export function ConfeccaoDePerucas() {
  const { setOpenSubMenu } = useMain()

  useEffect(() => {
    setOpenSubMenu(false)
  }, [setOpenSubMenu])

  return (
    <>
      <Helmet title="Confecção das Perucas" />
      <section className="flex items-center justify-center pb-8 pt-40 max-[480px]:pt-28">
        <div className="container">
          <Fade delay={200}>
            <h1 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-2xl">
              Confecção das Perucas
            </h1>
            <div className="pt-16">
              <div className="grid grid-cols-7 pb-8 max-[600px]:grid-cols-4">
                <img src={icon1} alt="Icone Tesoura" />
                <span className="max-[600px]:hidden">
                  <FaArrowRight color="#e6007e" size={80} />
                </span>
                <img src={icon2} alt="Icone Maquina" />
                <span className="max-[600px]:hidden">
                  <FaArrowRight color="#e6007e" size={80} />
                </span>
                <img src={icon3} alt="Icone Menina" />
                <span className="max-[600px]:hidden">
                  <FaArrowRight color="#e6007e" size={80} />
                </span>
                <img src={icon4} alt="Icone Coração" />
              </div>
              <p className="pt-4 text-lg font-normal text-purple-950 max-[480px]:text-sm">
                Para que as doações de cabelo virem nossas lindas perucas e
                transformem a jornada dos pacientes oncológicos, uma grande
                etapa é necessária: a costura.{' '}
                <br className="max-[480px]:hidden" /> Graças às nossas
                brilhantes costureiras, a mágica acontece e todo nosso trabalho
                é possível! <br className="max-[480px]:hidden" /> Hoje, a
                Cabelegria conta com a parceria do Coletivo de Mulheres
                Sartasiñani, que é responsável integralmente pela nossa
                produção.
              </p>
            </div>
          </Fade>
        </div>
      </section>
      <section className="flex items-center justify-center bg-pink-650 py-8">
        <div className="container">
          <div className="flex items-center justify-between gap-4 pb-8 max-[1024px]:flex-col">
            <div className="max-[1599px]:max-w-[25rem] max-[1279px]:max-w-full min-[1025px]:max-w-[29rem] min-[1600px]:max-w-[40rem]">
              <Fade delay={200}>
                <h2 className="pb-8 font-roboto text-4xl font-bold text-white max-[480px]:text-2xl">
                  Projeto Peruca Alegria
                </h2>
              </Fade>
              <Fade delay={250}>
                <p className="pb-4 text-sm font-normal text-white">
                  Em parceria com a SICOOB, em 2021, a Cabelegria lançou o
                  projeto Peruca Alegria, que teve como objetivo sanar uma das
                  grandes dificuldades da ONG até então: encontrar costureiras
                  qualificadas para produção de perucas.
                </p>
                <p className="pb-4 text-sm font-normal text-white">
                  Antes do projeto, a ONG contava com apenas 1 (uma) costureira
                  contratada em tempo integral e média de produção era de 50 a
                  80 unidades por mês.
                </p>
                <p className="pb-4 text-sm font-normal text-white">
                  Após o projeto, e com a parceria do Coletivo Sartasiñani, hoje
                  a Cabelegria tem capacidade de produzir mais de 500 perucas
                  por mês!
                </p>
                <p className="pb-4 text-sm font-normal text-white">
                  Confira ao lado mais detalhes deste lindo projeto:
                </p>
                <div className="h-auto w-full rounded-md">
                  <div className="h-24 rounded-md border-[1px] border-white p-4 max-[1599px]:h-32 max-[1024px]:h-auto">
                    <p
                      className={`font-regular text-center font-roboto text-base text-white`}
                    >
                      Apesar do sucesso do projeto, por falta de recursos
                      financeiros, deixamos de produzir mais de 350 perucas por
                      mês. Você pode ajudar a mudar essa realidade!
                    </p>
                    <a
                      href="https://doe.cabelegria.org/s/single_step"
                      target="_blank"
                      title="Adote uma peruca"
                      rel="noreferrer"
                      className="relative top-2 flex justify-center"
                    >
                      <Button
                        variant="transparent"
                        size="lg"
                        className="border-purple-950 bg-purple-950 font-roboto text-xl font-medium uppercase text-white"
                      >
                        Adote uma peruca
                      </Button>
                    </a>
                  </div>
                </div>
              </Fade>
            </div>
            <div className="relative mx-auto h-[398px] w-full max-[480px]:h-[192px] min-[1025px]:h-[280px] min-[1025px]:w-[500px] min-[1200px]:h-[350px] min-[1200px]:w-[650px] min-[1400px]:h-[398px] min-[1400px]:w-[710px] min-[1600px]:h-[450px] min-[1600px]:w-[800px]">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=qT3BziGEvsY"
                light={true}
                width="100%"
                height="100%"
              />
            </div>
          </div>
          {/* <div className="mx-auto h-auto w-[388px] rounded-md bg-white p-4 max-[1280px]:w-[354px] max-[1200px]:w-[350px] max-[1199px]:w-[319px] max-[1099px]:w-[289px] max-[1024px]:w-[388px] ">
            <div className="flex flex-col items-center justify-center gap-8 rounded-md border-[3px] border-pink-650 p-4">
              <p
                className={`font-regular text-center font-roboto text-xl text-pink-650`}
              >
                Apesar do sucesso do projeto, hoje a Cabelegria só consegue
                contratar a produção de <br className="max-[480px]:hidden" />{' '}
                150 perucas por mês, devido à falta de recursos financeiros.
                Precisamos da sua ajuda <br className="max-[480px]:hidden" />{' '}
                para mudar essa realidade!
              </p>
              <a
                href="https://doe.cabelegria.org/s/single_step"
                target="_blank"
                title="Saiba mais"
                rel="noreferrer"
              >
                <Button
                  variant="transparent"
                  size="lg"
                  className="border-pink-650 bg-pink-650 font-roboto text-xl font-medium text-white"
                >
                  Doe Aqui
                </Button>
              </a>
            </div>
          </div> */}
          {/* <Fade delay={400}>
            <div></div>
            <div>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=qT3BziGEvsY"
                light={true}
                width="100%"
                height="100%"
              />
            </div> */}
          {/* <div className="relative mx-auto h-[398px] w-full max-[480px]:h-[192px] min-[1025px]:h-[400px] min-[1200px]:h-[400px] min-[1400px]:h-[400px]  min-[1600px]:h-[400px]">
              <ReactPlayer
                url="https://www.youtube.com/watch?v=qT3BziGEvsY"
                light={true}
                width="100%"
                height="100%"
              />
            </div> */}
        </div>
      </section>
    </>
  )
}

import { Fade } from 'react-awesome-reveal'
import { Link } from 'react-router-dom'

import { useMain } from '@/contexts/mainContext'

import icon1 from '../assets/icon1.svg'
import icon2 from '../assets/icon2.svg'
import icon3 from '../assets/icon3.svg'
import { Button } from './ui/button'

interface HomeCardProps {
  bgColor: string
  hoverBgColor: string
  iconColor: string
  icon: 'icon1' | 'icon2' | 'icon3'
  text: string
  textColor: string
  hoverTextColor?: string
  borderColor: string
  btnText: string
  link: string
  time: number
}

export function HomeCard({
  bgColor,
  hoverBgColor,
  iconColor,
  icon,
  text,
  textColor,
  hoverTextColor,
  borderColor,
  btnText,
  link,
  time,
}: HomeCardProps) {
  const { setSuccess } = useMain()

  return (
    <div
      className={`${bgColor} flex flex-col items-center justify-center gap-12 py-12 lg:py-0`}
    >
      <Fade delay={time}>
        <div
          className={`${iconColor} flex h-48 w-48 items-center justify-center rounded-full`}
        >
          <img
            src={icon === 'icon1' ? icon1 : icon === 'icon2' ? icon2 : icon3}
            className="h-28 w-28"
            alt={text}
          />
        </div>
        <h1
          className={`${textColor} whitespace-pre-wrap text-center font-roboto text-3xl font-bold`}
        >
          {text}
        </h1>
        <Link to={`${link}`} onClick={() => setSuccess(false)}>
          <Button
            variant="transparent"
            size="lg"
            className={`${borderColor} ${textColor} ${hoverBgColor} ${hoverTextColor} font-roboto text-xl font-medium hover:border-transparent`}
          >
            {btnText}
          </Button>
        </Link>
      </Fade>
    </div>
  )
}

import { Helmet } from 'react-helmet-async'

import { CertificadoForm } from '@/components/certificado/certificadoform'
import { CertificadoSuccess } from '@/components/certificado/certificadosuccess'
import { SlideComoAjudar } from '@/components/slide-comoajudar'
import { useMain } from '@/contexts/mainContext'

export function Certificado() {
  const { success } = useMain()

  return (
    <>
      <Helmet title="Certificado" />
      <section className="flex justify-center py-40 max-[1279px]:items-center max-[1279px]:py-24">
        <div className="container">
          <div className="flex items-center justify-center max-[1279px]:flex-col max-[1279px]:gap-8 max-[1024px]:pt-8 min-[1280px]:gap-16 min-[1600px]:gap-40">
            <div className="h-[550px] w-[440px] max-[1024px]:h-auto max-[767px]:w-full">
              <SlideComoAjudar />
            </div>
            {success ? <CertificadoSuccess /> : <CertificadoForm />}
          </div>
        </div>
      </section>
    </>
  )
}

import { useQuery } from '@tanstack/react-query'
import { Fragment, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import { AgendaFn } from '@/api/agenda'
import { SlideBancoDePerucasMovel } from '@/components/slide-bancodeperucasmovel'
import { AgendaDTO } from '@/dtos/AgendaDTO'

export function BancoDePerucasMovel() {
  const [width, setWidth] = useState(window.innerWidth)

  const [agendaData, setAgendaData] = useState(0)
  const [currentAgenda, setCurrentAgenda] = useState(0)
  const [arrowUp, setArrowUp] = useState(0)

  function handleShowLessAgenda() {
    setAgendaData((moreAgenda) => moreAgenda - 1)
    setCurrentAgenda((prevState) => prevState - 1)
    setArrowUp((prevState) => prevState - 1)
  }

  function handleShowMoreAgenda() {
    setAgendaData((moreAgenda) => moreAgenda + 1)
    setCurrentAgenda((prevState) => prevState + 1)
    setArrowUp((prevState) => prevState + 1)
  }

  const { data: agenda, isFetching } = useQuery({
    queryKey: ['agenda'],
    queryFn: () => AgendaFn(),
  })

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    if (width <= 580) {
      setAgendaData(1)
    } else if (width >= 581 && width <= 1279) {
      setAgendaData(2)
    } else if (width >= 1280 && width <= 1399) {
      setAgendaData(2)
    } else if (width >= 1400 && width <= 1599) {
      setAgendaData(3)
    } else {
      setAgendaData(2)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  return (
    <>
      <Helmet title="Banco de Perucas Movel" />
      <section className="flex items-center justify-center py-20">
        <div className="container">
          <div className="flex items-start justify-between gap-4 py-20 max-[1279px]:flex-col max-[1279px]:items-center max-[1024px]:pt-8">
            <div className="h-[550px] w-[647px] max-[767px]:w-full">
              <Fade direction="left">
                <SlideBancoDePerucasMovel />
              </Fade>
            </div>
            <hr className="h-[75vh] border-l-[1px] border-purple-950 max-[1599px]:h-[80vh] max-[1279px]:h-0 max-[1279px]:w-full max-[1279px]:border-[1px] max-[1279px]:border-l-[0px]" />
            <div className="flex w-[53%] flex-col gap-[1.5rem] max-[1279px]:w-full">
              <Fade delay={200}>
                <h1 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-2xl">
                  Banco de Perucas Móvel
                </h1>
              </Fade>
              <Fade delay={250}>
                <div className="relative mt-8 h-[300px] rounded-md border-[3px] border-pink-650 max-[1599px]:h-[450px] max-[1399px]:h-[380px] max-[1024px]:max-w-full max-[580px]:h-auto">
                  <h2 className="absolute left-0 right-0 top-[-30px] mx-auto flex h-12 w-48 items-center justify-center rounded-full bg-pink-650 text-center font-roboto text-2xl font-bold text-white">
                    Agenda 2024
                  </h2>
                  <div className="px-4 pt-8 font-roboto text-sm font-normal text-pink-650">
                    {isFetching && <h1 className="text-4xl">Carregando...</h1>}
                    {arrowUp > 0 && (
                      <IoIosArrowUp
                        color="#e6007e"
                        size={30}
                        className="absolute right-0 top-[8px] mr-1 animate-bounce cursor-pointer"
                        onClick={handleShowLessAgenda}
                      />
                    )}
                    {agenda
                      ?.slice(currentAgenda, agendaData)
                      .map((agendaData: AgendaDTO) => {
                        return (
                          <Fragment key={agendaData.ag_id}>
                            <div className="pb-4">
                              <strong className="block pb-4">
                                &bull; {agendaData.ag_name}
                              </strong>
                              <strong>Dia:</strong> {agendaData.ag_day} <br />
                              <strong>Horário:</strong> {agendaData.ag_time}{' '}
                              <br />
                              <strong>Endereço: </strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: agendaData.ag_address,
                                }}
                              />
                            </div>
                          </Fragment>
                        )
                      })}
                    {agendaData < agenda?.length && (
                      <IoIosArrowDown
                        color="#e6007e"
                        size={30}
                        className="absolute bottom-0 right-0 mr-1 animate-bounce cursor-pointer"
                        onClick={handleShowMoreAgenda}
                      />
                    )}
                  </div>
                </div>
              </Fade>
              <Fade delay={300}>
                <p className="font-roboto text-sm font-normal text-purple-950 max-[1279px]:max-w-full">
                  O primeiro e único Banco de Perucas Móvel do mundo é um
                  projeto inovador da Cabelegria que transformou um caminhão em
                  um verdadeiro &quot;mini salão&quot;, onde pacientes
                  oncológicos podem receber um atendimento humanizado e
                  exclusivo no processo de escolha da sua peruca, levando
                  autoestima e sorrisos até a porta de hospitais, escolas,
                  eventos e etc.
                </p>
              </Fade>
              <Fade delay={350}>
                <div className="flex items-center justify-around rounded-md bg-pink-650 py-4 max-[1279px]:max-w-full max-[1024px]:flex-col max-[1024px]:justify-center max-[1024px]:gap-4">
                  <h3 className="font-roboto text-base font-bold text-white">
                    Para a retirada de perucas é <br /> indispensável a
                    apresentação <br /> dos documentos:
                  </h3>
                  <hr className="h-[10vh] border-l-[1px] border-white max-[1024px]:h-0 max-[1024px]:w-full max-[1024px]:border-[1px] max-[1024px]:border-l-[0px]" />
                  <ul className="max-[1024px]:px-4">
                    <li className="font-roboto text-base font-bold text-white">
                      &bull; Laudo médico atualizado (1 ano)*
                    </li>
                    <li className="font-roboto text-base font-bold text-white">
                      &bull; RG/CPF
                    </li>
                    <li className="font-roboto text-base font-bold text-white">
                      * Pacientes oncológicos precisam apresentar{' '}
                      <br className="block max-[480px]:hidden" /> comprovante de
                      quimioterapia
                    </li>
                  </ul>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

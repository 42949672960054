export function getInitials(fullName: string) {
  const nameArray = fullName.split(' ')
  const firstNameIn = nameArray[0].charAt(0).toUpperCase()
  const lastNameIn = nameArray[nameArray.length - 1].charAt(0).toUpperCase()
  return firstNameIn + lastNameIn
}

export function randomChars(length: number) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let result = ''
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length))
  }
  return result
}

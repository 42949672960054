import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { FaExclamationCircle } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { QueroUmaPerucaFormStep1 } from '@/components/queroumaperuca/queroumaperucaformstep1'
import { QueroUmaPerucaFormStep2 } from '@/components/queroumaperuca/queroumaperucaformstep2'
import { QueroUmaPerucaFormStep3 } from '@/components/queroumaperuca/queroumaperucaformstep3'
import { QueroUmaPerucaFormStep4 } from '@/components/queroumaperuca/queroumaperucaformstep4'
import { QueroUmaPerucaFormStep5 } from '@/components/queroumaperuca/queroumaperucaformstep5'
import { QueroUmaPerucaFormStep6 } from '@/components/queroumaperuca/queroumaperucaformstep6'
import { SlideQueroUmaPeruca } from '@/components/slide-queroumaperuca'
import { Button } from '@/components/ui/button'
import { useMain } from '@/contexts/mainContext'

import { Progress } from '../../components/ui/progress'

export function QueroUmaPeruca() {
  const { progress } = useMain()

  return (
    <>
      <Helmet title="Quero uma peruca" />
      <section className="flex items-center justify-center py-40 max-[1279px]:py-24">
        <div className="container">
          <div className="flex items-center justify-center gap-40 max-[1279px]:flex-col max-[1279px]:gap-8 max-[1024px]:pt-8">
            <div className="h-[550px] w-[440px] max-[1024px]:h-auto max-[767px]:w-full">
              <Fade direction="left">
                <SlideQueroUmaPeruca />
              </Fade>
            </div>
            <div className="w-[600px] max-[1279px]:w-full">
              <Fade delay={200}>
                {progress >= 101 ? (
                  <>
                    <h1 className="pb-2 font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
                      Pronto!
                    </h1>
                    <h2 className="pb-8 font-roboto text-2xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
                      Seu formulário foi preenchido com sucesso.
                    </h2>
                  </>
                ) : (
                  <h1 className="pb-8 font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
                    Para receber uma peruca, basta{' '}
                    <br className="max-[1024px]:hidden" />
                    preencher o formulário abaixo:
                  </h1>
                )}

                {progress <= 100 && (
                  <>
                    <div className="flex items-center gap-4 pb-8 max-[1024px]:flex-col max-[1024px]:items-start max-[480px]:items-center">
                      <FaExclamationCircle color="#e6007e" size={78} />
                      <div className="font-roboto text-sm font-normal text-pink-650 max-[480px]:text-center">
                        <span>Tenha em mãos os seguintes documentos</span>
                        <ul>
                          <li>
                            • Documento de identificação que conste número de
                            CPF e RG;
                          </li>
                          <li>
                            • Laudo médico (em caso de alopécia, atestado médico
                            informando o CID);
                          </li>
                          <li>
                            • Comprovante de Quimioterapia (exclusivo para
                            pacientes oncológicos);
                          </li>
                        </ul>
                      </div>
                    </div>
                    <Progress value={progress} className="mb-8" />
                  </>
                )}

                <div className="rounded-md border-[3px] border-pink-650 p-4 max-[1279px]:p-4 max-[1024px]:h-auto">
                  {progress === 15 ? (
                    <QueroUmaPerucaFormStep1 />
                  ) : progress === 25 ? (
                    <QueroUmaPerucaFormStep2 />
                  ) : progress === 50 ? (
                    <QueroUmaPerucaFormStep3 />
                  ) : progress === 75 ? (
                    <QueroUmaPerucaFormStep4 />
                  ) : progress === 100 ? (
                    <QueroUmaPerucaFormStep5 />
                  ) : progress > 100 ? (
                    <QueroUmaPerucaFormStep6 />
                  ) : (
                    ''
                  )}
                  {/* {progress === 15 ? (
                    <QueroUmaPerucaFormStep5 />
                  ) : (
                    <QueroUmaPerucaFormStep6 />
                  )} */}
                </div>

                {progress >= 101 && (
                  <div className="mt-8 flex">
                    <Link to="/a-ong" className="w-full">
                      <Button
                        size="lg"
                        className="flex h-[56px] w-full items-center justify-center rounded-full border-[1px] border-pink-650 bg-pink-650 font-roboto text-xl font-bold text-white  hover:bg-pink-650 max-[1279px]:w-full max-[560px]:text-base max-[480px]:h-[60px] max-[480px]:text-center"
                      >
                        Conheça mais sobre{' '}
                        <br className="hidden max-[1024px]:hidden max-[375px]:block lg:hidden" />{' '}
                        a Cabelegria
                      </Button>
                    </Link>
                  </div>
                )}
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

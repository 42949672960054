import { api } from '@/lib/axios'

export interface ParceirosData {
  data: {
    name: string
    company: string
    email: string
  }
}

export async function parceiros({ data }: ParceirosData) {
  await api.post('/cadastro-parceiros', { data })
}

import { QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import ReactDOM from 'react-dom/client'

import { App } from './App.tsx'
import { MainProvider } from './contexts/mainContext.tsx'
import { queryClient } from './lib/react-query.ts'

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MainProvider>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </MainProvider>
  </React.StrictMode>,
)

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { z } from 'zod'

import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useMain } from '@/contexts/mainContext'

const certificadoFormSchema = z.object({
  protocolo: z.string().min(1, 'Número de protocolo é obrigatório!'),
})

type CertificadoFormData = z.infer<typeof certificadoFormSchema>

export function CertificadoForm() {
  const { setSuccess, setProtocolo } = useMain()

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<CertificadoFormData>({
    resolver: zodResolver(certificadoFormSchema),
  })

  async function SendCerficiadoFormData(data: CertificadoFormData) {
    setProtocolo(data.protocolo)

    setSuccess(true)
  }

  return (
    <div className="w-[600px] max-[1279px]:w-full">
      <h1 className="pb-8 font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
        Acompanhe o seu <br /> Certificado de Doação
      </h1>
      <div className="max-[1279px]:p-4 max-[1024px]:h-auto">
        <form
          className="flex flex-col items-center justify-center gap-8 max-[1024px]:w-full max-[480px]:gap-4"
          onSubmit={handleSubmit(SendCerficiadoFormData)}
        >
          <h2 className="pb-4 font-roboto text-xl font-bold text-pink-650 max-[480px]:text-center max-[480px]:text-lg">
            Como forma de confirmar o recebimento da sua doação,
            disponibilizamos um certificado digital para download!{' '}
            <br className="max-[480px]:hidden" /> Para acessá-lo, digite seu
            número de protocolo abaixo:
          </h2>
          <div className="flex w-full gap-4 max-[480px]:flex-col">
            <div className="w-full">
              <Input
                type="text"
                {...register('protocolo')}
                placeholder="Protocolo:"
                className={
                  errors.protocolo?.message
                    ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                    : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
                }
              />
              {errors.protocolo?.message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  {errors.protocolo.message}
                </p>
              )}
            </div>
          </div>

          <div className="flex w-full gap-4 max-[480px]:flex-col">
            <p className="font-roboto text-sm font-normal text-pink-650">
              <strong>IMPORTANTE:</strong> Em ressonância com as diretrizes da
              Lei Geral de Proteção de Dados (13.709/2018), o Certificado de
              Doação estará disponível apenas para aqueles que preencheram o
              formulário de pré-cadastro{' '}
              <Link to="/como-ajudar/cadastro" className="underline">
                <strong>Disponível Aqui</strong>
              </Link>
            </p>
          </div>
          <Button
            type="submit"
            disabled={isSubmitting === true}
            variant="transparent"
            className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[480px]:w-full"
          >
            Procurar certificado
          </Button>
        </form>
      </div>
    </div>
  )
}

import { api } from '@/lib/axios'

export interface CertificadoData {
  protocolo: string
}

export async function CertificadoFn({ protocolo }: CertificadoData) {
  const response = await api.get(`certificado?protocolo=${protocolo}`)

  return response.data
}

import { GrClose } from 'react-icons/gr'

import { useMain } from '@/contexts/mainContext'

export function PopUp() {
  const { showPopUP, setShowPopUP } = useMain()

  function handlePopUP() {
    setShowPopUP(false)
  }

  return (
    <div className={showPopUP ? 'fixed inset-0 z-10 bg-black/40' : ''}>
      <div className="relative top-1/4 mx-auto block h-[450px] w-[800px] bg-bg-popUP bg-cover bg-center bg-no-repeat max-[991px]:w-4/5 max-[800px]:h-[300px] max-[580px]:h-[200px] max-[360px]:h-[180px]">
        <div className="z-50 h-[46px] w-full">
          <GrClose
            size={30}
            color="#ffffff"
            className="absolute right-2 top-2 cursor-pointer"
            onClick={handlePopUP}
          />
        </div>
        <a href="/adote" className="block h-full w-full"></a>
      </div>
    </div>
  )
}

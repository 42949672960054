interface AOngCardProps {
  bgColor: string
  title: string
  text?: string
  list?: boolean
  mW: string
}

export function AOngCard({ bgColor, title, text, list, mW }: AOngCardProps) {
  return (
    <div
      className={`${bgColor} bgRoxo flex h-80 w-80 flex-col items-center justify-center gap-4 rounded-full border-[1px] border-transparent text-center max-[1024px]:h-64 max-[1024px]:w-64 max-[480px]:mx-auto`}
    >
      <span className="font-roboto text-3xl font-bold text-white">{title}</span>
      <span
        className={`${mW} font-roboto text-sm font-normal text-white max-[1024px]:text-xs`}
      >
        {list ? (
          <ul>
            <li>• Respeito</li>
            <li>• Transparência</li>
            <li>• Ética</li>
            <li>• Empatia</li>
            <li>• Comprometimento</li>
          </ul>
        ) : (
          <> {text} </>
        )}
      </span>
    </div>
  )
}

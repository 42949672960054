import { Link } from 'react-router-dom'

import { useMain } from '@/contexts/mainContext'

import { Button } from '../ui/button'

export function ComoAjudarFormSuccess() {
  const { protocolo, setSuccess } = useMain()

  return (
    <div className="w-[600px] max-[1279px]:w-full">
      <h1 className="pb-12 font-roboto text-4xl font-bold leading-[3rem] text-purple-950 max-[480px]:text-center max-[480px]:text-xl">
        Pronto! Agora é só imprimir <br className="max-[560px]:hidden" /> seu
        número de protocolo e <br className="max-[560px]:hidden" />
        <span className="border-b-[2px] border-b-pink-650">
          enviar junto com o seu cabelo ;)
        </span>
      </h1>
      <div className="flex justify-between gap-4 pb-8 max-[480px]:flex-col max-[480px]:items-center">
        <div>
          <h2 className="font-roboto text-2xl font-bold  text-purple-950 max-[480px]:text-center">
            Protocolo: <br /> <span className="font-normal">{protocolo}</span>
          </h2>
        </div>
        <div className="flex flex-col items-start gap-2">
          {/* <Button
            variant="transparent"
            size="lg"
            className="flex h-[50px] w-[180px] items-center justify-center gap-2 rounded-full bg-purple-950 px-0 font-roboto text-sm font-bold text-white max-[1024px]:w-full max-[560px]:text-sm"
          >
            Baixar protocolo{' '}
            <FaArrowDown className="max-[480px]:hidden" size={15} />
          </Button> */}
          <div className="flex w-full flex-col justify-center rounded-3xl border-[1px] border-purple-950 p-4">
            <span className="text-center font-roboto text-xs font-normal text-purple-950">
              Também enviamos uma cópia <br /> para o seu e-mail!
            </span>
          </div>
        </div>
      </div>
      <div className="flex justify-between gap-4 pb-12 max-[480px]:flex-col">
        <div className="flex w-full flex-col justify-center bg-pink-650 p-4 max-[480px]:items-center max-[480px]:text-center">
          <span className="font-roboto text-xs font-normal text-white">
            <strong className="block pb-2">Para envio pelos Correios</strong>
            A/C Cabelegria <br />
            Caixa Postal – 75207 <br />
            São Paulo/SP <br />
            CEP. 02415-972
          </span>
        </div>
        <div className="flex w-full flex-col items-center justify-center gap-4 bg-pink-650 p-4">
          <span className="text-center font-roboto text-xs font-normal text-white">
            <strong>
              Prefere entregar sua mecha pessoalmente? <br /> Confira nossos
              Pontos de Coleta:
            </strong>
          </span>
          <Link to="/pontos-de-coleta">
            <Button
              type="submit"
              variant="transparent"
              className="rounded-full border-white bg-transparent font-roboto text-sm font-medium text-white"
            >
              Clique aqui
            </Button>
          </Link>
        </div>
      </div>
      <Link to="/certificado" onClick={() => setSuccess(false)}>
        <Button
          size="lg"
          className="flex h-[56px] w-full items-center justify-center rounded-full border-[1px] border-purple-950 bg-purple-950 font-roboto text-xl font-bold text-white hover:bg-purple-950 hover:text-white max-[1279px]:w-full max-[560px]:text-base max-[480px]:h-[60px] max-[480px]:text-center"
        >
          Acompanhe aqui o{' '}
          <br className="hidden max-[1024px]:hidden max-[375px]:block lg:hidden" />
          seu certificado de doação
        </Button>
      </Link>
    </div>
  )
}

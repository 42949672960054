import { api } from '@/lib/axios'

export interface DuvidasData {
  data: {
    name: string
    email: string
    messageForm: string
  }
}

export async function duvidas({ data }: DuvidasData) {
  await api.post('/duvidas', { data })
}

import { api } from '@/lib/axios'

export interface VoluntariosData {
  data: {
    email: string
  }
}

export async function voluntarios({ data }: VoluntariosData) {
  await api.post('/voluntarios', { data })
}

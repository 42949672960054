import { Button } from '../ui/button'

interface ComoAjudarCardProps {
  borderColor: string
  bgColor: string
  number: number
  title: string
  subTitleColor: string
  subTitleBold?: string
  subTitle: string
  hoverColor: string
  link: string
}

export function ComoAjudarCard({
  borderColor,
  bgColor,
  number,
  title,
  subTitleColor,
  subTitleBold,
  subTitle,
  hoverColor,
  link,
}: ComoAjudarCardProps) {
  return (
    <div
      className={`${borderColor} gap4 flex h-[450px] w-[388px] items-center justify-center rounded-md border-[3px] max-[1280px]:w-[354px] max-[1200px]:w-[350px] max-[1199px]:w-[319px] max-[1099px]:w-[289px] max-[1024px]:w-[388px] max-[1024px]:px-[0.8rem] max-[480px]:w-full`}
    >
      <div className="flex flex-col items-center justify-center gap-8 py-8">
        <div className={`${bgColor} flex w-56 justify-center rounded-full`}>
          <h2 className="flex max-w-[160px] items-center justify-center gap-2  font-roboto text-2xl font-bold leading-7 text-white">
            <span className="text-7xl">{number}</span> {title}
          </h2>
        </div>
        <p
          className={`font-regular ${subTitleColor} max-w-44 text-center font-roboto text-xl`}
        >
          {subTitleBold && (
            <>
              <strong>{subTitleBold}</strong>
              <br />
            </>
          )}{' '}
          {subTitle}
        </p>
        <a href={`#${link}`} title="Saiba mais">
          <Button
            variant="transparent"
            size="lg"
            className={`${borderColor} ${subTitleColor} ${hoverColor} font-roboto text-xl font-medium hover:text-white`}
          >
            Saiba Mais
          </Button>
        </a>
      </div>
    </div>
  )
}

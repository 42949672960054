import { api } from '@/lib/axios'

export interface NewsletterData {
  data: {
    email: string
  }
}

export async function newsletter({ data }: NewsletterData) {
  await api.post('/newsletter', { data })
}

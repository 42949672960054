import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'

import { ComoAjudarForm } from '@/components/comoajudar/comoajudarform'
import { ComoAjudarFormSuccess } from '@/components/comoajudar/comoajudarformsuccess'
import { SlideComoAjudar } from '@/components/slide-comoajudar'
import { useMain } from '@/contexts/mainContext'

export function ComoAjudarFormCadastro() {
  const { success } = useMain()

  return (
    <>
      <Helmet title="Como Doar - Cadastro" />
      <section className="flex items-center justify-center py-40 max-[1279px]:py-24">
        <div className="container">
          <div className="flex justify-center gap-40 max-[1279px]:flex-col max-[1279px]:items-center max-[1279px]:gap-8 max-[1024px]:pt-8">
            <div className="h-[550px] w-[440px] max-[1024px]:h-auto max-[767px]:w-full">
              <Fade direction="left">
                <SlideComoAjudar />
              </Fade>
            </div>
            {success ? (
              <ComoAjudarFormSuccess />
            ) : (
              <Fade direction="right">
                <ComoAjudarForm />
              </Fade>
            )}
          </div>
        </div>
      </section>
    </>
  )
}

import { useEffect } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'

import { SlideDepoimentos } from '@/components/slide-depoimentos'
import { useMain } from '@/contexts/mainContext'

export function Depoimentos() {
  const { setOpenSubMenu } = useMain()

  useEffect(() => {
    setOpenSubMenu(false)
  }, [setOpenSubMenu])

  return (
    <>
      <Helmet title="Depoimentos" />
      <section className="flex items-center justify-center py-40 max-[1024px]:py-28">
        <div className="container">
          <Fade delay={200}>
            <h1 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-3xl">
              Depoimentos
            </h1>
            <div className="pt-8">
              <SlideDepoimentos />
            </div>
          </Fade>
        </div>
      </section>
    </>
  )
}

// Import Swiper styles
import 'swiper/css'

import { Fragment, useEffect } from 'react'
// import required modules
import { Autoplay } from 'swiper/modules'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

import { useMain } from '@/contexts/mainContext'

import queroumaperucaArr from '../utils/queroumaperucaslide.json'

export function SlideQueroUmaPeruca() {
  const { queroUmaPerucaSlide, setQueroUmaPerucaSlide } = useMain()

  useEffect(() => {
    const response = queroumaperucaArr

    setQueroUmaPerucaSlide(response)
  }, [setQueroUmaPerucaSlide])

  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      {queroUmaPerucaSlide.map((slide) => {
        return (
          <Fragment key={slide.id}>
            {slide.show === true && (
              <SwiperSlide>
                <img
                  src={`assets/slides/queroumaperuca/${slide.imagem}`}
                  alt={`Quero uma peruca ${slide.id}`}
                  className="h-[550px] w-[440px] object-contain max-[1024px]:object-cover"
                />
              </SwiperSlide>
            )}
          </Fragment>
        )
      })}
    </Swiper>
  )
}

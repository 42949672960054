import { Link, LinkProps, useLocation } from 'react-router-dom'

export type NavLinkProps = LinkProps

export function NavLink(props: NavLinkProps) {
  const { pathname } = useLocation()

  // text-xs font-medium uppercase text-white no-underline hover:font-bold data-[current=true]:font-bold xl:text-sm

  return (
    <Link
      data-current={pathname === props.to}
      className="relative p-2 font-roboto text-xs font-medium uppercase text-white after:absolute after:bottom-[-1px] after:left-0 after:block  after:h-[1px] after:w-full after:origin-left after:scale-x-0 after:bg-white after:transition after:duration-300 after:content-[''] hover:border-white after:hover:scale-x-100 data-[current=true]:border-b-[1px] data-[current=true]:font-bold max-[1023px]:text-2xl max-[580px]:p-0 lg:text-[0.65rem] min-[1024px]:p-0 xl:text-[0.75rem] min-[1400px]:p-2 min-[1600px]:text-sm min-[1600px]:after:bottom-[3px]
      "
      {...props}
    />
  )
}

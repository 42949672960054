import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { voluntarios } from '@/api/voluntarios'

import { Button } from './ui/button'
import { Input } from './ui/input'

interface TimeCardProps {
  picture?: string
  name?: string
  role?: string
  voluntary?: boolean
}

const voluntaryForm = z.object({
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
})

type VoluntaryForm = z.infer<typeof voluntaryForm>

export function TimeCard({ picture, name, role, voluntary }: TimeCardProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<VoluntaryForm>({
    resolver: zodResolver(voluntaryForm),
  })

  const { mutateAsync: voluntariosFn } = useMutation({
    mutationFn: voluntarios,
    onSuccess: () => {
      toast.success(
        '😄 Sua mensagem foi enviada com sucesso, fique de olho na sua caixa de e-mail!',
      )
      reset()
    },
    onError: () => {
      toast.error('🙁 Erro, tente novamente mais tarde!')
      reset()
    },
  })

  async function handleVoluntary(data: VoluntaryForm) {
    const voluntarioData = {
      email: data.email,
    }

    await voluntariosFn({ data: voluntarioData })
  }

  return (
    <div
      className={
        name === undefined
          ? `flex h-64 flex-col items-center justify-center rounded-md border-[1px] bg-cyan-650 py-8`
          : `flex h-64 flex-col items-center justify-center rounded-md border-[1px] border-cyan-650 py-8`
      }
    >
      {voluntary === true ? (
        <div className="flex max-w-[12rem] flex-col gap-2">
          <h3 className="font-roboto text-xl font-bold text-white">
            Quer fazer parte dessa equipe? Seja um voluntário!
          </h3>
          <hr className="w-full border-[1px] border-white" />
          <form
            className="flex flex-col gap-2 max-[1024px]:justify-center"
            onSubmit={handleSubmit(handleVoluntary)}
          >
            <div className="space-y-2">
              <Input
                id="email"
                type="email"
                placeholder="E-mail"
                {...register('email')}
                autoComplete="off"
                className={
                  errors.email?.message
                    ? 'focus:ring-red border-[1px] border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:text-red-600 focus:ring-red-600 focus-visible:outline-none focus-visible:ring-red-600'
                    : 'focus:ring-red border-[1px] border-solid bg-transparent font-roboto font-medium text-white outline-none placeholder:font-roboto placeholder:text-white focus:ring-purple-950 focus-visible:outline-none focus-visible:ring-purple-950'
                }
              />
              {errors.email?.message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  {errors.email.message}
                </p>
              )}
            </div>

            <Button
              type="submit"
              disabled={isSubmitting}
              variant="transparent"
              className="bg-white font-roboto text-sm font-medium text-cyan-650"
            >
              Enviar
            </Button>
          </form>
        </div>
      ) : name === undefined ? (
        <div className="flex max-w-[12rem] flex-col gap-2">
          <h3 className="font-roboto text-xl font-bold text-white">
            Conheça <br /> nosso time
          </h3>
          <hr className="w-full border-[1px] border-white" />
          <p className="whitespace-break-spaces font-roboto text-sm font-normal text-white max-[1024px]:text-left max-[1024px]:text-xs">
            Conheça as mentes criativas e corações generosos que impulsionam o
            nosso trabalho
          </p>
        </div>
      ) : (
        <>
          <img
            src={picture}
            alt={`${name} | ${role}`}
            className="mb-4 h-36 w-36"
          />
          <h3 className="font-roboto text-lg font-bold text-cyan-650">
            {name}
          </h3>
          <h4 className="font-roboto text-sm font-normal text-cyan-650">
            {role}
          </h4>
        </>
      )}
    </div>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { cpf } from 'cpf-cnpj-validator'
import { ChangeEvent, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { z } from 'zod'

import { useMain } from '@/contexts/mainContext'
import { QueroUmaPerucaDTOStep1 } from '@/dtos/QueroUmaPerucaDTO'
import cepRequest from '@/utils/cep'

import { Button } from '../ui/button'
import { Input } from '../ui/input'

const queroUmaPerucaFormSchema = z.object({
  name: z.string().min(1, 'Nome Completo é obrigatório!'),
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
  phone: z.string().min(1, 'Celular é obrigatório!'),
  rg: z.string().min(1, 'RG é obrigatório!'),
  CPF: z.string().transform((value, ctx) => {
    const CPF = value
    if (CPF === '') {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'CPF é obrigatório!',
      })
    } else if (cpf.isValid(CPF) === false) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Este CPF é inválido',
      })
    }
    return value
  }),
  cep: z.string().min(1, 'CEP é obrigatório!'),
  cityUF: z.string().min(1, 'Cidade é obrigatório!'),
  address: z.string().min(1, 'Endereço é obrigatório!'),
  number: z.string().min(1, 'Número é obrigatório!'),
  complement: z.string(),
  hospital: z.string().min(1, 'Hospital de tratamento é obrigatório!'),
})

type QueroUmaPerucaFormData = z.infer<typeof queroUmaPerucaFormSchema>

export function QueroUmaPerucaFormStep1() {
  const {
    CEP,
    setCEP,
    cepMessage,
    setCEPMessage,
    cidadeField,
    setCidadeField,
    endereco,
    setEndereco,
    cidade,
    setCidade,
    uf,
    setUF,
    cidadeMessage,
    setCidadeMessage,
    setEnderecoMessage,
    enderecoMessage,
    progress,
    setProgress,
    setFormValues,
  } = useMain()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<QueroUmaPerucaFormData>({
    resolver: zodResolver(queroUmaPerucaFormSchema),
    mode: 'all',
  })

  async function handleCEPChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target

    setCEP(value)

    if (CEP.length >= 9) {
      setCEPMessage(false)
    }
  }

  async function SendQueroUmaPerucaFormData(data: QueroUmaPerucaDTOStep1) {
    setFormValues(data)
    setProgress(progress + 10)
  }

  useEffect(() => {
    setEndereco('')
    setCidadeField('')

    async function handleSearchCEP() {
      const foundCEP = await cepRequest.get(`/${CEP}/json`)

      await foundCEP.data.length
      setCEP(foundCEP.data.cep)
      setEndereco(foundCEP.data.logradouro)
      setCidade(foundCEP.data.localidade)
      setUF(foundCEP.data.uf)
      setCEPMessage(false)
      setEnderecoMessage(false)
      setCidadeMessage(false)
      setCidadeField(`${cidade}/${uf}`)
    }

    handleSearchCEP()
  }, [
    CEP,
    cidade,
    setCEP,
    setCEPMessage,
    setCidade,
    setCidadeField,
    setCidadeMessage,
    setEndereco,
    setEnderecoMessage,
    setUF,
    uf,
  ])

  return (
    <form
      className="flex flex-col items-center justify-center gap-8 max-[1024px]:w-full max-[480px]:gap-4"
      onSubmit={handleSubmit(SendQueroUmaPerucaFormData)}
    >
      <div className="flex w-full gap-4 max-[480px]:flex-col">
        <div className="w-full">
          <Input
            type="text"
            {...register('name')}
            autoComplete="off"
            placeholder="Nome completo:"
            className={
              errors.name?.message
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.name?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.name.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <Input
            type="email"
            {...register('email')}
            placeholder="E-mail:"
            className={
              errors.email?.message
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.email?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.email.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full gap-4 max-[480px]:flex-col">
        <div className="w-full">
          <InputMask
            mask="(99) 99999-9999"
            autoComplete="off"
            maskChar=""
            type="tel"
            {...register('phone')}
            placeholder="Telefone:"
            className={
              errors.phone?.message
                ? 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-red-600 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-red-600 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-red-600 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                : 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-pink-650 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-pink-650 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
            }
          />
          {errors.phone?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.phone.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <InputMask
            mask="99.999.999-9"
            maskChar=""
            autoComplete="off"
            type="tel"
            {...register('rg')}
            placeholder="RG:"
            className={
              errors.rg?.message
                ? 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-red-600 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-red-600 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-red-600 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                : 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-pink-650 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-pink-650 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
            }
          />
          {errors.rg?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.rg.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full gap-4 max-[480px]:flex-col">
        <div className="w-full">
          <InputMask
            mask="999.999.999-99"
            maskChar=""
            autoComplete="off"
            type="tel"
            {...register('CPF')}
            placeholder="CPF:"
            className={
              errors.CPF?.message
                ? 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-red-600 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-red-600 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-red-600 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                : 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-pink-650 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-pink-650 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
            }
          />
          {errors.CPF?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.CPF.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <InputMask
            type="tel"
            mask="99999-999"
            autoComplete="off"
            maskChar=""
            {...register('cep')}
            onBlur={handleCEPChange}
            defaultValue={CEP}
            placeholder="CEP:"
            className={
              errors.cep?.message && cepMessage === true
                ? 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-red-600 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-red-600 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-red-600 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                : 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-pink-650 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-pink-650 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
            }
          />
          {errors.cep?.message && cepMessage === true && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.cep.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full gap-4 max-[480px]:flex-col">
        <div className="w-full">
          <Input
            type="text"
            {...register('address')}
            autoComplete="off"
            placeholder="Endereço:"
            defaultValue={endereco}
            className={
              errors.address?.message && enderecoMessage === true
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.address?.message && enderecoMessage === true && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.address.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <Input
            type="text"
            {...register('number')}
            placeholder="Número:"
            autoComplete="off"
            className={
              errors.number?.message
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.number?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.number.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full gap-4 max-[480px]:flex-col">
        <div className="w-full">
          <Input
            type="text"
            {...register('cityUF')}
            placeholder="Cidade/UF:"
            autoComplete="off"
            defaultValue={cidadeField}
            className={
              errors.cityUF?.message && cidadeMessage === true
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.cityUF?.message && cidadeMessage === true && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.cityUF.message}
            </p>
          )}
        </div>
        <div className="w-full">
          <Input
            type="text"
            {...register('complement')}
            autoComplete="off"
            placeholder="Complemento:"
            className={
              errors.complement?.message
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.complement?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.complement.message}
            </p>
          )}
        </div>
      </div>
      <div className="flex w-full gap-4">
        <div className="w-full">
          <Input
            type="text"
            {...register('hospital')}
            autoComplete="off"
            placeholder="Hospital de tratamento:"
            className={
              errors.hospital?.message
                ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
            }
          />
          {errors.hospital?.message && (
            <p className="pt-1 text-left text-sm text-red-600">
              {errors.hospital.message}
            </p>
          )}
          <p className="pt-4 font-roboto text-sm font-normal text-pink-650 max-[480px]:text-xs">
            *Todas as informações fornecidas serão utilizadas unicamente para o
            fim especificado e estão protegidas dentro das normas estipuladas
            pela Lei Geral de Proteção de Dados.
          </p>
        </div>
      </div>

      <Button
        type="submit"
        variant="transparent"
        className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[480px]:w-full"
      >
        Enviar
      </Button>
    </form>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useMain } from '@/contexts/mainContext'
import { QueroUmaPerucaDTOStep3 } from '@/dtos/QueroUmaPerucaDTO'

import { Button } from '../ui/button'
import { Input } from '../ui/input'

const perucaIdealComprimentoFormSchema = z.object({
  perucaIdealComprimento: z
    .string()
    .min(1, { message: 'Por favor selecione uma das opções' }),
})

type PerucaIdealComprimentoFormData = z.infer<
  typeof perucaIdealComprimentoFormSchema
>

export function QueroUmaPerucaFormStep3() {
  const { progress, setProgress, setFormValues } = useMain()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PerucaIdealComprimentoFormData>({
    resolver: zodResolver(perucaIdealComprimentoFormSchema),
    mode: 'all',
  })

  async function SendQueroUmaPerucaFormData(data: QueroUmaPerucaDTOStep3) {
    setFormValues(data)
    setProgress(progress + 25)
  }

  return (
    <>
      <h2 className="font-roboto text-2xl font-bold text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        Escolha sua peruca ideal:
      </h2>
      <span className="block font-roboto text-xl font-normal text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        Agora, escolha o comprimento.
      </span>
      <form
        className="mt-8 flex flex-col items-center justify-center gap-8 max-[1024px]:w-full max-[480px]:gap-4"
        onSubmit={handleSubmit(SendQueroUmaPerucaFormData)}
      >
        <div className="flex items-start gap-4 max-[600px]:flex-col">
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealComprimento')}
              value="Curtíssimo"
              id="curtissimo"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="curtissimo"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Curtíssimo
            </label>
          </div>
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealComprimento')}
              value="Curto"
              id="curto"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="curto"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Curto
            </label>
          </div>
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealComprimento')}
              value="Médio"
              id="medio"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="medio"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Médio
            </label>
          </div>
          {/* <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealComprimento')}
              value="Longo"
              id="longo"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="longo"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Longo
            </label>
          </div> */}
        </div>

        {errors.perucaIdealComprimento?.message && (
          <p className="pt-1 text-left text-sm text-red-600">
            {errors.perucaIdealComprimento.message ===
            'Expected string, received null'
              ? 'Por favor selecione uma das opções'
              : 'Expected string, received null'}
          </p>
        )}
        <Button
          type="submit"
          variant="transparent"
          className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[480px]:w-full"
        >
          Próximo
        </Button>
      </form>
    </>
  )
}

import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

import bullets from '../assets/bulletsRoxo.jpg'
import logo404 from '../assets/logo404.png'

export function NotFound() {
  return (
    <>
      <Helmet title="Desculpe-nos, página não encontrada" />
      <main className="flex flex-1 flex-col">
        <section className="flex h-screen items-center justify-center max-[320px]:h-[680px]">
          <div className="container">
            <div className="flex flex-col items-center justify-center gap-8 max-[1200px]:gap-4">
              <img
                src={logo404}
                alt="Logo Cabelegria"
                className="h-[46px] w-[244px] max-[480px]:h-auto max-[480px]:w-[190px]"
              />
              <h1 className="text-center font-roboto text-[10rem] font-bold text-pink-650 max-[580px]:text-2xl">
                Ops!
              </h1>
              <div className="flex items-center gap-4 max-[699px]:flex-col">
                <h2 className="text-center font-roboto text-4xl font-normal text-pink-650 max-[580px]:text-2xl">
                  Essa página mudou de endereço!
                </h2>
                <img
                  src={bullets}
                  alt="Bullets Roxo"
                  className="h-[37px] max-[699px]:w-[73px]"
                />
              </div>
              <p className="text-center font-roboto text-2xl font-normal text-cyan-650 max-[580px]:text-lg">
                Para melhorar a sua experiência, estamos{' '}
                <br className="block max-[580px]:hidden" /> atualizando o nosso
                site e por isso, alguns{' '}
                <br className="block max-[580px]:hidden" /> endereços não podem
                mais ser encontrados!{' '}
                <br className="block max-[580px]:hidden" /> Mas fique tranquilo,
                acesse:
              </p>
              <Link
                className="text-center font-roboto text-2xl font-bold text-purple-950 max-[580px]:text-lg"
                to="https://www.cabelegria.org"
              >
                www.cabelegria.org
              </Link>
              <p className="text-center font-roboto text-2xl font-normal text-cyan-650 max-[580px]:text-lg">
                que você encontrará todas as informações{' '}
                <br className="block max-[580px]:hidden" /> que precisa, ok?
              </p>
              <h3 className="text-center font-roboto text-4xl font-normal text-pink-650 max-[580px]:text-2xl">
                Com carinho, <br />
                <strong> Equipe Cabelegria!</strong>
              </h3>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}

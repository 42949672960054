import CountUp from 'react-countup'

interface CountUpCardProps {
  icon: string
  text: string
  countEnd: number
  decimals?: number
  delay: number
}

export function CountUpCard({
  icon,
  text,
  countEnd,
  decimals,
  delay,
}: CountUpCardProps) {
  return (
    <div className="flex h-52 w-52 flex-col items-center justify-center rounded-full border-[1px] border-white bg-transparent max-[480px]:mx-auto">
      <img src={`assets/dados/${icon}`} alt={text} className="h-12 w-12" />
      <CountUp
        className="pt-4 font-roboto text-4xl font-bold text-white"
        end={countEnd}
        enableScrollSpy
        decimals={decimals}
        delay={delay}
      />
      <span className="font-roboto text-sm font-normal text-white">{text}</span>
    </div>
  )
}

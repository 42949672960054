import { api } from '@/lib/axios'

export interface PontosdeColetaData {
  state: string
}

export async function PontosdeColetaFn({ state }: PontosdeColetaData) {
  const response = await api.get(`pontosdecoleta?state=${state}`)

  return response.data
}

import { Link } from 'react-router-dom'

interface ContributionCardProps {
  active?: boolean
  title: string
  subtitle: string
  url?: string
}

export function ContributionCard({
  active,
  title,
  subtitle,
  url,
}: ContributionCardProps) {
  return (
    <>
      {url ? (
        <Link to={url} target="_blank">
          <div
            className={
              active
                ? 'flex h-64 w-64 flex-col items-center justify-center gap-4 rounded-full border-[1px] border-white bg-white text-center max-[480px]:mx-auto'
                : 'flex h-64 w-64 flex-col items-center justify-center gap-4 rounded-full border-[1px] border-white text-center max-[480px]:mx-auto'
            }
          >
            <span
              className={
                active
                  ? 'pt-4 font-roboto text-3xl font-bold text-pink-650'
                  : 'pt-4 font-roboto text-3xl font-bold text-white'
              }
            >
              {title}
            </span>
            <span
              className={
                active
                  ? 'max-w-[12.5rem] font-roboto text-sm font-normal text-pink-650'
                  : 'max-w-[12.5rem] font-roboto text-sm font-normal text-white'
              }
            >
              {subtitle}
            </span>
          </div>
        </Link>
      ) : (
        <div
          className={
            active
              ? 'flex h-64 w-64 flex-col items-center justify-center gap-4 rounded-full border-[1px] border-white bg-white text-center max-[480px]:mx-auto'
              : 'flex h-64 w-64 flex-col items-center justify-center gap-4 rounded-full border-[1px] border-white text-center max-[480px]:mx-auto'
          }
        >
          <span
            className={
              active
                ? 'pt-4 font-roboto text-3xl font-bold text-pink-650'
                : 'whitespace-pre-wrap pt-4 font-roboto text-3xl font-bold text-white'
            }
          >
            {title}
          </span>
          <span
            className={
              active
                ? 'max-w-[12.5rem] font-roboto text-sm font-normal text-pink-650'
                : 'max-w-[12.5rem] font-roboto text-sm font-normal text-white'
            }
          >
            {subtitle}
          </span>
        </div>
      )}
    </>
  )
}

import { api } from '@/lib/axios'

export interface ComoAjudarData {
  data: {
    name: string
    email: string
    telefone?: string
    protocolCode: string
    newsletter?: string
    whatsapp?: string
  }
}

export async function comoajudar({ data }: ComoAjudarData) {
  await api.post('/leads', { data })
}

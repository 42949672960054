import { useQuery } from '@tanstack/react-query'
import { Download, Printer } from 'lucide-react'
import { Link } from 'react-router-dom'

import { CertificadoFn } from '@/api/certificado'
import { useMain } from '@/contexts/mainContext'
import { CertificadoDTO } from '@/dtos/CertificadoDTO'

export function CertificadoSuccess() {
  const { protocolo } = useMain()

  const { data: certificado, isFetching } = useQuery({
    queryKey: ['certificado', protocolo],
    queryFn: () =>
      CertificadoFn({
        protocolo,
      }),
  })

  const isIFrame = (input: HTMLElement | null): input is HTMLIFrameElement =>
    input !== null && input.tagName === 'IFRAME'

  function handlePrint() {
    const iframe = document.getElementById('print')
    if (isIFrame(iframe) && iframe.contentWindow) {
      iframe.contentWindow.print()
    }
  }

  return (
    <>
      {isFetching ? (
        <h1 className="pb-4 font-roboto text-3xl font-bold uppercase text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
          Carregando...
        </h1>
      ) : certificado === undefined || certificado.length === 0 ? (
        <div>
          <h1 className="pb-4 font-roboto text-3xl font-bold uppercase text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
            Nenhum certificado foi encontrado
          </h1>
        </div>
      ) : (
        <>
          {certificado.map((certificadoData: CertificadoDTO) => {
            return (
              <div
                className="rounded-md border border-purple-950 p-4 max-[1279px]:w-full max-[1279px]:p-4 max-[1024px]:h-auto"
                key={certificadoData.id}
              >
                <h1 className="pb-4 font-roboto text-3xl font-bold uppercase text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
                  Protocolo: {certificadoData.protocolo}
                </h1>
                <div className="rounded-md border border-pink-650 p-4 max-[1279px]:w-full max-[1279px]:p-4 max-[1024px]:h-auto">
                  <table className="w-full border-collapse">
                    <thead className="border-b border-b-pink-650 text-center font-roboto text-lg font-bold text-pink-650">
                      <tr>
                        <th className="p-3">Nome do doador</th>
                        <th className="p-3 max-[1024px]:hidden">Imprimir</th>
                        <th className="p-3 max-[1024px]:hidden">Download</th>
                      </tr>
                    </thead>
                    <tbody className="cursor-pointer text-center font-roboto text-base font-normal text-pink-650">
                      <tr className="border-b border-b-pink-650">
                        <td className="p-4">{certificadoData.nome}</td>
                        <td className="p-4 max-[1024px]:hidden">
                          <iframe
                            id="print"
                            src={`https://www.cabelegria.org/doadores/certificado_imagem.php?id=${certificadoData.id}`}
                            className="hidden"
                          />
                          <Printer
                            size={40}
                            className="mx-auto"
                            onClick={handlePrint}
                          />
                        </td>
                        <td className="p-4 max-[1024px]:hidden">
                          <Link
                            to={`https://www.cabelegria.org/doadores/certificado_imagem.php?id=${certificadoData.id}`}
                            target="_blank"
                            download
                          >
                            <Download size={40} className="mx-auto" />
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h2 className="p-4 text-center font-roboto text-sm font-normal text-pink-650">
                    <strong>Importante:</strong> Para fazer o download ou
                    imprimir o seu certificado, acesse o site utilizando um
                    computador.
                  </h2>
                </div>
              </div>
            )
          })}
        </>
      )}
    </>
  )
}

import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'
import { z } from 'zod'

import { duvidas } from '@/api/duvidas'
import { DuvidasBox } from '@/components/duvidas-box'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Textarea } from '@/components/ui/textarea'

const duvidasFormSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório!'),
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
  message: z.string().min(1, 'Mensagem é obrigatório!'),
})

type DuvidasForm = z.infer<typeof duvidasFormSchema>

export function Duvidas() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitting, errors },
  } = useForm<DuvidasForm>({
    resolver: zodResolver(duvidasFormSchema),
  })

  const { mutateAsync: duvidasFn } = useMutation({
    mutationFn: duvidas,
    onSuccess: () => {
      toast.success(
        '😄 Sua mensagem foi enviada com sucesso, fique de olho na sua caixa de e-mail!',
      )
      reset()
    },
    onError: () => {
      toast.error('🙁 Erro, tente novamente mais tarde!')
      reset()
    },
  })

  async function handleDuvidas(data: DuvidasForm) {
    const duvidasData = {
      name: data.name,
      email: data.email,
      messageForm: data.message,
    }

    await duvidasFn({ data: duvidasData })
  }

  return (
    <>
      <Helmet title="Dúvidas" />
      {/* <section className="flex items-center justify-center max-[1279px]:py-32 xl:h-screen xl:py-[33rem]"> */}
      <section className="flex items-center justify-center py-40 max-[1024px]:py-28">
        <div className="container">
          <div className="flex items-center max-[1024px]:flex-col">
            <div className="flex w-3/4 flex-col gap-6 max-[1024px]:w-full">
              <Fade delay={200}>
                <h1 className="font-roboto text-5xl font-bold text-purple-950 max-[480px]:text-2xl">
                  Dúvidas Frequentes
                </h1>
              </Fade>
              <Fade delay={250}>
                <h2 className="font-roboto text-3xl font-normal text-purple-950 max-[480px]:text-xl">
                  Como podemos ajudar você?
                </h2>
                <DuvidasBox />
              </Fade>
            </div>
            <Fade delay={200}>
              <div className="flex h-[354px] flex-col justify-center gap-6 self-baseline rounded-md border border-purple-950 p-8 max-[1279px]:p-4 max-[1024px]:h-auto">
                <div className="text-center">
                  <h2 className="font-roboto text-3xl font-bold text-purple-950 max-[480px]:text-xl">
                    Ainda com dúvidas?
                  </h2>
                  <h3 className="font-roboto text-3xl font-normal text-purple-950 max-[1279px]:text-2xl max-[480px]:text-xl">
                    Mande uma mensagem!
                  </h3>
                </div>
                <form
                  className="flex flex-col items-center justify-center gap-4 max-[1024px]:w-full"
                  onSubmit={handleSubmit(handleDuvidas)}
                >
                  <div className="flex gap-4 max-[1024px]:w-full max-[480px]:flex-col">
                    <div className="w-full">
                      <Input
                        id="nome"
                        type="text"
                        {...register('name')}
                        placeholder="Nome"
                        className={
                          errors.name?.message
                            ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                            : 'focus:ring-red border border-solid border-purple-950 bg-transparent font-roboto font-medium capitalize text-purple-950 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-purple-950'
                        }
                      />
                      {errors.name?.message && (
                        <p className="pt-1 text-left text-sm text-red-600">
                          {errors.name.message}
                        </p>
                      )}
                    </div>
                    <div className="w-full">
                      <Input
                        id="email"
                        type="email"
                        {...register('email')}
                        autoComplete="off"
                        placeholder="E-mail"
                        className={
                          errors.email?.message
                            ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                            : 'focus:ring-red border border-solid border-purple-950 bg-transparent font-roboto font-medium text-purple-950 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-purple-950'
                        }
                      />
                      {errors.email?.message && (
                        <p className="pt-1 text-left text-sm text-red-600">
                          {errors.email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="w-full">
                    <Textarea
                      placeholder="Escreva aqui a sua mensagem"
                      {...register('message')}
                      className={
                        errors.message?.message
                          ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                          : 'focus:ring-red border border-solid border-purple-950 bg-transparent font-roboto font-medium text-purple-950 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-purple-950'
                      }
                    />
                    {errors.message?.message && (
                      <p className="pt-1 text-left text-sm text-red-600">
                        {errors.message.message}
                      </p>
                    )}
                  </div>

                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    variant="transparent"
                    className="border-purple-950 bg-purple-950 font-roboto text-sm font-medium text-white"
                  >
                    Enviar
                  </Button>
                </form>
              </div>
            </Fade>
          </div>
        </div>
      </section>
    </>
  )
}

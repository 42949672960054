import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { ChangeEvent, useState } from 'react'
import { useForm } from 'react-hook-form'
import InputMask from 'react-input-mask'
import { z } from 'zod'

import { comoajudar } from '@/api/como-ajudar'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { useMain } from '@/contexts/mainContext'
import { getInitials, randomChars } from '@/utils/get-protocolo'

const comoAjudarFormSchema = z.object({
  name: z.string().min(1, 'Nome Completo é obrigatório!'),
  email: z
    .string()
    .min(1, 'E-mail é obrigatório!')
    .email('Digite um e-mail válido'),
  telefone: z.string().optional(),
  terms: z.boolean().optional(),
  terms2: z.boolean().optional(),
})

type ComoAjudarFormData = z.infer<typeof comoAjudarFormSchema>

export function ComoAjudarForm() {
  const { setProtocolo, protocolo, setSuccess } = useMain()
  const [name, setName] = useState('')
  const [message, setMessage] = useState(false)
  const [whatsapp, setWhatsapp] = useState('')

  function handleNameChange(event: ChangeEvent<HTMLInputElement>) {
    setMessage(false)

    const { value } = event.target
    setName(value)

    if (name) {
      const initials = getInitials(name)
      const randomChar = randomChars(4)
      setProtocolo(`${initials}-${randomChar}`)
    } else {
      setMessage(true)
    }
  }

  function handleWhatsappChange(event: ChangeEvent<HTMLInputElement>) {
    const { value } = event.target
    setWhatsapp(value)
  }

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<ComoAjudarFormData>({
    resolver: zodResolver(comoAjudarFormSchema),
  })

  const { mutateAsync: comoAjudarDataFn } = useMutation({
    mutationFn: comoajudar,
  })

  async function SendComoAjudarFormData(data: ComoAjudarFormData) {
    console.log(data.terms)
    console.log(data.terms2)

    if (data.terms === true && data.terms2 === undefined) {
      const comoAjudarData = {
        name: data.name,
        email: data.email,
        protocolCode: protocolo,
        newsletter: 'Sim',
      }

      await comoAjudarDataFn({ data: comoAjudarData })
    } else if (data.terms === false && data.terms2 === undefined) {
      const comoAjudarData = {
        name: data.name,
        email: data.email,
        protocolCode: protocolo,
        newsletter: 'Não',
      }

      await comoAjudarDataFn({ data: comoAjudarData })
    } else if (data.terms === false && data.terms2 === true) {
      const comoAjudarData = {
        name: data.name,
        email: data.email,
        telefone: data.telefone,
        protocolCode: protocolo,
        newsletter: 'Não',
        whatsapp: 'Sim',
      }

      await comoAjudarDataFn({ data: comoAjudarData })
    } else if (data.terms === true && data.terms2 === true) {
      const comoAjudarData = {
        name: data.name,
        email: data.email,
        telefone: data.telefone,
        protocolCode: protocolo,
        newsletter: 'Sim',
        whatsapp: 'Sim',
      }

      await comoAjudarDataFn({ data: comoAjudarData })
    } else if (data.terms === false && data.terms2 === false) {
      const comoAjudarData = {
        name: data.name,
        email: data.email,
        protocolCode: protocolo,
        newsletter: 'Não',
      }

      await comoAjudarDataFn({ data: comoAjudarData })
    }

    setSuccess(true)
  }

  return (
    <div className="w-[600px] max-[1279px]:w-full">
      <h1 className="pb-8 font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-center max-[480px]:text-2xl">
        Para doar seu cabelo, <br /> basta preencher o formulário abaixo:
      </h1>
      <div className="rounded-md border-[3px] border-pink-650 p-8 max-[1279px]:p-4 max-[1024px]:h-auto">
        <form
          className="flex flex-col items-center justify-center gap-4 max-[1024px]:w-full max-[480px]:gap-4"
          onSubmit={handleSubmit(SendComoAjudarFormData)}
        >
          <div className="flex w-full gap-4 max-[480px]:flex-col">
            <div className="w-full">
              <Input
                type="text"
                {...register('name')}
                placeholder="Nome completo:"
                autoComplete="off"
                onChange={handleNameChange}
                className={
                  errors.name?.message || message
                    ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium capitalize text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                    : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium capitalize text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
                }
              />
              {errors.name?.message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  {errors.name.message}
                </p>
              )}
              {message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  Nome Completo é obrigatório!
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-4 max-[480px]:flex-col">
            <div className="w-full">
              <Input
                type="email"
                {...register('email')}
                autoComplete="off"
                placeholder="E-mail:"
                className={
                  errors.email?.message
                    ? 'focus:ring-red border border-solid border-red-600 bg-transparent font-roboto font-medium text-red-600 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-red-600'
                    : 'focus:ring-red border border-solid border-pink-650 bg-transparent font-roboto font-medium text-pink-650 outline-none placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650'
                }
              />
              {errors.email?.message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  {errors.email.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-4 max-[480px]:flex-col">
            <div className="w-full">
              <InputMask
                mask="(99) 99999-9999"
                autoComplete="off"
                maskChar=""
                type="tel"
                {...register('telefone')}
                onChange={handleWhatsappChange}
                placeholder="Whatsapp:"
                className={
                  errors.telefone?.message
                    ? 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-red-600 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-red-600 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-red-600 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                    : 'focus:ring-red flex h-10 w-full rounded-md border border-solid border-pink-650 bg-transparent px-3 py-2 font-roboto text-sm font-medium capitalize text-pink-650 outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:font-roboto placeholder:normal-case placeholder:text-pink-650 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50'
                }
              />
              {errors.telefone?.message && (
                <p className="pt-1 text-left text-sm text-red-600">
                  {errors.telefone.message}
                </p>
              )}
            </div>
          </div>
          <div className="flex w-full gap-4 max-[480px]:flex-col">
            <p className="font-roboto text-sm font-normal text-pink-650">
              <strong>IMPORTANTE:</strong> O certificado de doação será
              disponibilizado apenas mediante preenchimento do formulário de
              doação e envio do respectivo número de protocolo junto da mecha de
              cabelo.
            </p>
          </div>
          <div className="flex w-full items-center gap-2">
            <input
              type="checkbox"
              {...register('terms')}
              className="h-5 w-5 appearance-none border-[1px] border-pink-650 checked:border-pink-650 checked:bg-pink-650 max-[480px]:h-6 max-[480px]:w-8"
            />
            <p className="font-roboto text-sm font-normal text-pink-650">
              <strong>
                Aceito receber por e-mail as novidades da Cabelegria
              </strong>
            </p>
          </div>
          {whatsapp !== '' && (
            <div className="flex w-full items-center gap-2">
              <input
                type="checkbox"
                {...register('terms2')}
                className="h-5 w-5 appearance-none border-[1px] border-pink-650 checked:border-pink-650 checked:bg-pink-650 max-[480px]:h-6 max-[480px]:w-8"
              />
              <p className="font-roboto text-sm font-normal text-pink-650">
                <strong>
                  Aceito receber por WhatsApp as novidades da Cabelegria
                </strong>
              </p>
            </div>
          )}
          <Button
            type="submit"
            disabled={isSubmitting === true}
            variant="transparent"
            className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[480px]:w-1/2"
          >
            Próximo
          </Button>
        </form>
      </div>
    </div>
  )
}

import { useQuery } from '@tanstack/react-query'
import { Fragment, MouseEvent, useEffect, useState } from 'react'
import { Fade } from 'react-awesome-reveal'
import { Helmet } from 'react-helmet-async'
import { GrFormClock, GrLocation } from 'react-icons/gr'
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import { PontosdeColetaFn } from '@/api/pontos-de-coleta'
import { PontosdeColetaDTO } from '@/dtos/PontodeColetaDTO'

import pontosdecoletaIMG from '../../assets/pontosdecoleta.jpg'

export function PontosdeColeta() {
  const [width, setWidth] = useState(window.innerWidth)
  const [listPontos, setListPontos] = useState<PontosdeColetaDTO[]>([])
  const [id, setID] = useState(0)
  const [state, setState] = useState('SP')
  const [showPonto, setShowPonto] = useState(5)
  const [currentPonto, setCurrentPonto] = useState(0)
  const [showPontoColeta, setShowPontoColeta] = useState(1)
  const [currentPontoColeta, setCurrentPontoColeta] = useState(0)
  const [arrowUp, setArrowUp] = useState(0)
  const [arrowUpColeta, setArrowUpColeta] = useState(0)

  function handleShowLessPontos() {
    setShowPonto((morePontos) => morePontos - 1)
    setCurrentPonto((prevState) => prevState - 1)
    setArrowUp((prevState) => prevState - 1)
  }

  function handleShowMorePontos() {
    setShowPonto((morePontos) => morePontos + 1)
    setCurrentPonto((prevState) => prevState + 1)
    setArrowUp((prevState) => prevState + 1)
  }

  function handleShowLessPontosColeta() {
    setShowPontoColeta((morePontos) => morePontos - 1)
    setCurrentPontoColeta((prevState) => prevState - 1)
    setArrowUpColeta((prevState) => prevState - 1)
  }

  function handleShowMorePontosColeta() {
    setShowPontoColeta((morePontos) => morePontos + 1)
    setCurrentPontoColeta((prevState) => prevState + 1)
    setArrowUpColeta((prevState) => prevState + 1)
  }

  function handleStateChange(event: MouseEvent<HTMLLIElement>) {
    setShowPonto(5)
    setCurrentPonto(0)

    setShowPontoColeta(1)
    setCurrentPontoColeta(0)

    setListPontos([])

    const { id } = event.currentTarget.dataset

    setState(String(id))

    refetch()
  }

  const {
    data: pontosdecoleta,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['pontosdecoleta', state],
    queryFn: () =>
      PontosdeColetaFn({
        state,
      }),
  })

  function handleCurrentPontoColeta(currentId: number) {
    setID(currentId)
  }

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    if (width <= 580) {
      setShowPonto(4)
    } else if (width >= 581 && width <= 1279) {
      setShowPonto(6)
    } else if (width >= 1280 && width <= 1399) {
      setShowPonto(5)
    } else if (width >= 1400 && width <= 1599) {
      setShowPonto(5)
    } else {
      setShowPonto(5)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [width])

  useEffect(() => {
    if (id > 0) {
      const pontodecoleta = pontosdecoleta.filter(
        (ponto: PontosdeColetaDTO) => ponto.pt_id >= id,
      )
      setListPontos(pontodecoleta)
    }
  }, [id])

  return (
    <>
      <Helmet title="Pontos de Coleta" />
      <section className="flex items-center justify-center py-24">
        <div className="container">
          <div className="flex items-start justify-between gap-16 pt-10 max-[1279px]:flex-col max-[1279px]:items-center max-[1279px]:gap-8 max-[1024px]:pt-8">
            <div className="flex flex-col gap-4 max-[1279px]:w-full">
              <Fade delay={200}>
                <h1 className="font-roboto text-4xl font-bold text-purple-950 max-[480px]:text-3xl">
                  Pontos de Coleta
                </h1>
                <p className="font-roboto text-sm font-normal text-pink-650">
                  Confira abaixo os estabelecimentos parceiros da Cabelegria que
                  estão aptos a receber sua doação de cabelo:
                </p>
              </Fade>
              <Fade delay={250}>
                <ul className="flex flex-row gap-4 pt-4 max-[1024px]:flex-nowrap max-[1024px]:overflow-x-auto">
                  <li
                    className={
                      state === 'SP'
                        ? `activePontodeColeta flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                        : `flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                    }
                    onClick={handleStateChange}
                    data-id="SP"
                  >
                    <span className="flex h-full w-full items-center justify-center text-center font-roboto text-xl font-bold text-purple-950 hover:text-white max-[1024px]:text-sm">
                      Estado <br /> de SP
                    </span>
                  </li>
                  <li
                    className={
                      state === 'RJ'
                        ? `activePontodeColeta flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                        : `flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                    }
                    onClick={handleStateChange}
                    data-id="RJ"
                  >
                    <span className="flex h-full w-full items-center justify-center text-center font-roboto text-xl font-bold text-purple-950 hover:text-white max-[1024px]:text-sm">
                      Estado <br /> do RJ
                    </span>
                  </li>
                  {/* <li
                    className={
                      state === 'RS'
                        ? `activePontodeColeta flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                        : `flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                    }
                    onClick={handleStateChange}
                    data-id="RS"
                  >
                    <span className="flex h-full w-full items-center justify-center text-center font-roboto text-xl font-bold text-purple-950 hover:text-white max-[1024px]:text-sm">
                      Estado <br /> do RS
                    </span>
                  </li> */}
                  <li
                    className={
                      state === 'SC'
                        ? `activePontodeColeta flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                        : `flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                    }
                    onClick={handleStateChange}
                    data-id="SC"
                  >
                    <span className="flex h-full w-full items-center justify-center text-center font-roboto text-xl font-bold text-purple-950 hover:text-white max-[1024px]:text-sm">
                      Estado <br /> do SC
                    </span>
                  </li>
                  {/* <li
                    className={
                      state === 'PT'
                        ? `activePontodeColeta flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                        : `flex h-28 w-28 cursor-pointer items-center justify-center rounded-full border-[1px] border-purple-950 hover:border-pink-650 hover:bg-pink-650 max-[1024px]:h-20 max-[1024px]:w-20 max-[1024px]:flex-shrink-0 max-[1024px]:flex-grow-0`
                    }
                    onClick={handleStateChange}
                    data-id="PT"
                  >
                    <span className="flex h-full w-full items-center justify-center text-center font-roboto text-xl font-bold text-purple-950 hover:text-white max-[1024px]:text-sm">
                      Portugal
                    </span>
                  </li> */}
                </ul>
              </Fade>
              <Fade delay={300}>
                <div className="flex flex-col gap-[1.5rem]">
                  <div className="relative mt-4 h-[280px] rounded-md border-[3px] border-pink-650 max-[1024px]:max-w-full max-[580px]:h-[241px]">
                    <div className="px-4 pt-8 font-roboto text-sm font-normal text-pink-650">
                      {isFetching && (
                        <h2 className="text-2xl">Carregando...</h2>
                      )}
                      {arrowUp > 0 && (
                        <IoIosArrowUp
                          color="#e6007e"
                          size={30}
                          className="absolute right-0 top-[8px] mr-1 animate-bounce cursor-pointer"
                          onClick={handleShowLessPontos}
                        />
                      )}
                      {pontosdecoleta
                        ?.slice(currentPonto, showPonto)
                        .map((ponto: PontosdeColetaDTO) => {
                          return (
                            <Fragment key={ponto.pt_id}>
                              <div className="pb-4">
                                <h2
                                  className="flex cursor-pointer items-center gap-4 font-roboto text-base font-bold uppercase text-pink-650 max-[480px]:text-xs"
                                  onClick={() =>
                                    handleCurrentPontoColeta(ponto.pt_id)
                                  }
                                >
                                  <span className="block h-6 w-6 rounded-full bg-pink-650" />
                                  {ponto.pt_name}
                                </h2>
                              </div>
                            </Fragment>
                          )
                        })}
                      {showPonto < pontosdecoleta?.length && (
                        <IoIosArrowDown
                          color="#e6007e"
                          size={30}
                          className="absolute bottom-0 right-0 mr-1 animate-bounce cursor-pointer"
                          onClick={handleShowMorePontos}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Fade>
            </div>
            <hr className="h-[75vh] border-l-[1px] border-purple-950 max-[1599px]:h-[80vh] max-[1279px]:h-0 max-[1279px]:w-full max-[1279px]:border-[1px] max-[1279px]:border-l-[0px]" />
            <div className="w-[647px] max-[767px]:w-full">
              <Fade delay={200}>
                {listPontos.length === 0 ? (
                  <img
                    src={pontosdecoletaIMG}
                    alt="Pontos de Coleta"
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <>
                    {listPontos
                      ?.slice(currentPontoColeta, showPontoColeta)
                      .map((ponto: PontosdeColetaDTO) => {
                        return (
                          <div
                            className="flex flex-col gap-[1.5rem] py-12 max-[1279px]:py-0"
                            key={ponto.pt_id}
                          >
                            <h2 className="flex items-center gap-4 font-roboto text-xl font-bold uppercase text-pink-650 max-[480px]:text-lg">
                              <span className="block h-6 w-6 rounded-full bg-pink-650" />
                              {ponto.pt_name}
                            </h2>
                            <p
                              className="max-w-[42rem] font-roboto text-sm font-normal text-pink-650 max-[1279px]:max-w-full"
                              dangerouslySetInnerHTML={{
                                __html: ponto.pt_desc,
                              }}
                            />
                            <div className="flex max-w-[30rem] flex-col gap-4 rounded-md border-[1px] border-pink-650 p-5 max-[1279px]:max-w-full">
                              <h3 className="flex gap-4 font-roboto text-base font-normal leading-[1.3rem] text-pink-650">
                                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-pink-650">
                                  <GrLocation color="#ffffff" size={23} />
                                </span>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ponto.pt_address,
                                  }}
                                />
                              </h3>
                              <h4 className="flex gap-4 font-roboto text-base font-normal leading-[1.3rem] text-pink-650">
                                <span className="flex h-8 w-8 items-center justify-center rounded-full bg-pink-650">
                                  <GrFormClock color="#ffffff" size={30} />
                                </span>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ponto.pt_time_scheduling,
                                  }}
                                />
                              </h4>
                            </div>
                            <div className="flex flex-col">
                              {arrowUpColeta > 0 && (
                                <IoIosArrowUp
                                  color="#e6007e"
                                  size={30}
                                  className="flex animate-bounce cursor-pointer self-end"
                                  onClick={handleShowLessPontosColeta}
                                />
                              )}
                              {showPontoColeta < listPontos?.length && (
                                <IoIosArrowDown
                                  color="#e6007e"
                                  size={30}
                                  className="flex animate-bounce cursor-pointer self-end"
                                  onClick={handleShowMorePontosColeta}
                                />
                              )}
                            </div>
                          </div>
                        )
                      })}
                  </>
                )}
              </Fade>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

// Import Swiper styles
import 'swiper/css'

import { useEffect } from 'react'
// import required modules
import { Autoplay } from 'swiper/modules'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

import { useMain } from '@/contexts/mainContext'

import homeArr from '../utils/homeslide.json'

export function SlideHome() {
  const { homeSlide, setHomeSlide } = useMain()

  useEffect(() => {
    const response = homeArr

    setHomeSlide(response)
  }, [setHomeSlide])

  return (
    <Swiper
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      {homeSlide.map((slide) => {
        return (
          slide.show === true && (
            <SwiperSlide key={slide.id}>
              <img
                src={`assets/slides/home/${slide.imagem}`}
                width="560"
                height="430"
                alt={`Peruca não é só cabelo ${slide.id}`}
                className="h-full w-full object-cover"
              />
            </SwiperSlide>
          )
        )
      })}
    </Swiper>
  )
}

// Import Swiper styles
import 'swiper/css'

import { Fragment, useEffect } from 'react'
// import required modules
import { Autoplay } from 'swiper/modules'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

import { useMain } from '@/contexts/mainContext'

import comoajudarArr from '../utils/comoajudarslide.json'

export function SlideComoAjudar() {
  const { comoAjudarSlide, setComoAjudarSlide } = useMain()

  useEffect(() => {
    const response = comoajudarArr

    setComoAjudarSlide(response)
  }, [setComoAjudarSlide])

  return (
    <Swiper
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[Autoplay]}
    >
      {comoAjudarSlide.map((slide) => {
        return (
          <Fragment key={slide.id}>
            {slide.show === true && (
              <SwiperSlide>
                <img
                  src={`/assets/slides/comoajudar/${slide.imagem}`}
                  alt={`Como Ajudar ${slide.id}`}
                  className="h-[550px] w-[440px] object-contain max-[1024px]:object-cover"
                />
              </SwiperSlide>
            )}
          </Fragment>
        )
      })}
    </Swiper>
  )
}

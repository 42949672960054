import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useMain } from '@/contexts/mainContext'
import { QueroUmaPerucaDTOStep2 } from '@/dtos/QueroUmaPerucaDTO'

import { Button } from '../ui/button'
import { Input } from '../ui/input'

const perucaIdealTipoFormSchema = z.object({
  perucaIdealTipo: z
    .string()
    .min(1, { message: 'Por favor selecione uma das opções' }),
})

type PerucaIdealTipoFormData = z.infer<typeof perucaIdealTipoFormSchema>

export function QueroUmaPerucaFormStep2() {
  const { progress, setProgress, setFormValues } = useMain()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PerucaIdealTipoFormData>({
    resolver: zodResolver(perucaIdealTipoFormSchema),
    mode: 'all',
  })

  async function SendQueroUmaPerucaFormData(data: QueroUmaPerucaDTOStep2) {
    setFormValues(data)

    setProgress(progress + 25)
  }

  return (
    <>
      <h2 className="font-roboto text-2xl font-bold text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        Escolha sua peruca ideal:
      </h2>
      <span className="block font-roboto text-xl font-normal text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        Primeiro, vamos escolher o tipo do cabelo.
      </span>
      <form
        className="mt-8 flex flex-col items-center justify-center gap-8 max-[1024px]:w-full max-[480px]:gap-4"
        onSubmit={handleSubmit(SendQueroUmaPerucaFormData)}
      >
        <div className="flex items-start gap-4 max-[600px]:flex-col">
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealTipo')}
              value="1. Liso"
              id="liso"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="liso"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              1. <br /> Liso
            </label>
          </div>
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealTipo')}
              value="2. Ondulado"
              id="ondulado"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="ondulado"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              2. <br /> Ondulado
            </label>
          </div>
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealTipo')}
              value="3. Cacheado"
              id="cacheado"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="cacheado"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              3. <br /> Cacheado
            </label>
          </div>
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              {...register('perucaIdealTipo')}
              value="4. Crespo"
              id="crespo"
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="crespo"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              4. <br /> Crespo
            </label>
          </div>
        </div>

        {errors.perucaIdealTipo?.message && (
          <p className="pt-1 text-left text-sm text-red-600">
            {errors.perucaIdealTipo.message === 'Expected string, received null'
              ? 'Por favor selecione uma das opções'
              : 'Expected string, received null'}
          </p>
        )}
        <Button
          type="submit"
          variant="transparent"
          className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[480px]:w-full"
        >
          Próximo
        </Button>
      </form>
    </>
  )
}

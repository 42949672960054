import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { useMain } from '@/contexts/mainContext'
import { QueroUmaPerucaDTOStep4 } from '@/dtos/QueroUmaPerucaDTO'

import { Button } from '../ui/button'
import { Input } from '../ui/input'

const perucaIdealCorFormSchema = z.object({
  perucaIdealCor: z
    .string()
    .min(1, { message: 'Por favor selecione uma das opções' }),
})

type PerucaIdealCorFormData = z.infer<typeof perucaIdealCorFormSchema>

export function QueroUmaPerucaFormStep4() {
  const { progress, setProgress, setFormValues } = useMain()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PerucaIdealCorFormData>({
    resolver: zodResolver(perucaIdealCorFormSchema),
  })

  async function SendQueroUmaPerucaFormData(data: QueroUmaPerucaDTOStep4) {
    setFormValues(data)
    setProgress(progress + 25)
  }
  return (
    <>
      <h2 className="font-roboto text-2xl font-bold text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        Escolha sua peruca ideal:
      </h2>
      <span className="block font-roboto text-xl font-normal text-pink-650 max-[1279px]:text-center max-[480px]:text-lg">
        E, por último, a cor.
      </span>
      <form
        className="mt-8 flex flex-col items-center justify-center gap-8 max-[1024px]:w-full max-[480px]:gap-4"
        onSubmit={handleSubmit(SendQueroUmaPerucaFormData)}
      >
        <div className="flex items-start gap-4 max-[600px]:flex-col">
          <div className="flex h-28 w-28">
            <Input
              type="radio"
              value="Loiro"
              id="loiro"
              {...register('perucaIdealCor')}
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="loiro"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Loiro
            </label>
          </div>
          <div className="flex h-28 w-28 items-center">
            <Input
              type="radio"
              value="Preto"
              id="preto"
              {...register('perucaIdealCor')}
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="preto"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Preto
            </label>
          </div>
          <div className="flex h-28 w-28 items-center">
            <Input
              type="radio"
              value="Moreno"
              id="moreno"
              {...register('perucaIdealCor')}
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="moreno"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Moreno
            </label>
          </div>
          <div className="flex h-28 w-28 items-center">
            <Input
              type="radio"
              value="Ruivo"
              id="ruivo"
              {...register('perucaIdealCor')}
              className="absolute h-28 w-28 cursor-pointer items-center justify-center rounded-full border border-solid border-pink-650"
            />
            <label
              htmlFor="ruivo"
              className="relative flex h-28 w-28 cursor-pointer items-center justify-center rounded-full font-roboto text-xl font-bold text-pink-650"
            >
              Ruivo
            </label>
          </div>
        </div>
        {errors.perucaIdealCor?.message && (
          <p className="pt-1 text-left text-sm text-red-600">
            {errors.perucaIdealCor.message === 'Expected string, received null'
              ? 'Por favor selecione uma das opções'
              : 'Expected string, received null'}
          </p>
        )}
        <Button
          type="submit"
          variant="transparent"
          className="w-1/4 border-pink-650 bg-pink-650 font-roboto text-sm font-medium text-white max-[480px]:w-full"
        >
          Próximo
        </Button>
      </form>
    </>
  )
}
